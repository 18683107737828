import { useState, useEffect, useCallback, useContext } from "react";
import AuthContext from "../context/AuthProvider";

const useAuth = () => {
    return useContext(AuthContext);

    // const authData = JSON.parse(localStorage.getItem('auth'));
    // localStorage.removeItem('auth');
    // localStorage.setItem('auth', JSON.stringify({ token, user }));
}

export default useAuth;