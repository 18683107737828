import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUsers, faDatabase, faChartPie, faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import Keys from "../utils/constants";

const NavBar = () => {
    const location = useLocation();
    const items = [
        { name: Keys.MAIN, id: "main", link: '/', icon: faHome },
        { name: Keys.USERS, id: "users", link: '/users', icon: faUsers },
        { name: Keys.INFORMATIONS, id: "informations", link: '/informations', icon: faDatabase },
        { name: Keys.STUDENTS, id: "students", link: '/students', icon: faGraduationCap},
        { name: Keys.STATISTICS, id: "statistics", link: '/statistics', icon: faChartPie},
    ];

    return (
        <ul className="nav-bar" id="nav-bar">
            {
                items.map((item, index)=>{
                    return (
                        <Link to={item.link} className={"nav-item"} key={'nav-bar-item-'+item.id}>
                            <div className={"nav-link " + (location.pathname != item.link && 'collapsed')} data-bs-target={`#${item.id}`} data-bs-toggle="collapse">
                                <div className="icon-bg"><FontAwesomeIcon className='text-dark' icon={item.icon}/></div>
                                <span>{item.name}</span>
                            </div>
                        </Link>
                    );
                })
            }
        </ul>
    );
}

export default NavBar;