export default class Utils {
    static getAvatar(user) {
        try {
            return user.firstName.charAt(0) + user.lastName.charAt(0);
        } catch (err) {}
    }

    static getFirstChar(s) { return s.charAt(0); }

    static toggleSidebar() {
        let el = 'body'.trim();
        document.querySelector(el).classList.toggle('toggle-sidebar');
    }

    static getTimestampDate(timestamp) {
        let date = new Date(timestamp);
        let dd = String(date.getDate()).padStart(2, '0');
        let mm = String(date.getMonth() + 1).padStart(2, '0');
        let yyyy = date.getFullYear();
        return `${dd}/${mm}/${yyyy}`;
    }

    static getTimestampDateHm(timestamp) {
        let date = new Date(timestamp);
        let dd = String(date.getDate()).padStart(2, '0');
        let mm = String(date.getMonth() + 1).padStart(2, '0');
        let yyyy = date.getFullYear();
        let hour = String(date.getHours()).padStart(2, '0');
        let minute = String(date.getMinutes()).padStart(2, '0');
    
        return `${dd}/${mm}/${yyyy} ${hour}:${minute}`;
    }

    static getMonthYear() {
        let date = new Date();
        let mm = String(date.getMonth() + 1).padStart(2, '0');
        let yyyy = date.getFullYear();

        return `${mm}/${yyyy}`;
    }

    static getMonth() {
        let date = new Date();
        let mm = String(date.getMonth() + 1).padStart(2, '0');
    
        return `${mm}`;
    }
    
    static copyToClipboard(id, value) {
        document.querySelector(id).addEventListener('click', () => {
            navigator.clipboard.writeText(value);
        });
    }
}