import Keys from "../../utils/constants";
import {Modal, Button } from 'react-bootstrap';

const ModalU = ({...props}) => {
    return (
        <Modal {...props} size={props.size} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.children}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>{Keys.BACK}</Button>
                {props.onSubmit &&
                    <Button variant={props.actionbtnvariant} onClick={props.onSubmit}>{props.actionbtntext}</Button>
                }
            </Modal.Footer>
        </Modal>
    );
}

export default ModalU;