import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const InputField = ({...props}) => {
    return (
        <div className={props.formClass}>
            <input type={props.type} name={props.name} value={props.value} onChange={props.onChange} className={props.inputClass} placeholder={props.hint}/>
            {props.icon && <FontAwesomeIcon className='text-secondary input-field-icon' icon={props.icon}/>}
        </div>
    );
}

export default InputField;