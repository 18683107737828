import { useState } from 'react';
import Keys from "../../../utils/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

const Server = () => {
    const [serverActive, setServerActive] = useState(true);

    return (
        <div className="d-flex flex-column card-16-outline m-3 p-3">
            <div className="d-flex align-items-center justify-content-between">
                <span className="fs-3 medium mb-2">{Keys.SETTINGS}/{Keys.SERVER}</span>
                <span className="badge bg-danger p-2">
                    <FontAwesomeIcon className="me-2" icon={faTriangleExclamation}/>
                    {Keys.SECTION_FOR_ADMIN}
                </span>
            </div>
            <div className="d-flex flex-column bg-red-50 rounded-3 py-4 px-4 mt-4">
                <div className="d-flex felx-row align-items-center justify-content-between ms-4">
                    <label className="medium fg-dark">{Keys.APP_VERSION}</label>
                    <input type="text" name="serverActive" value={"1.0.0"} className="form-control w-25" />
                </div>
                <div className="d-flex felx-row align-items-center justify-content-between form-switch mt-2">
                    <label className="medium fg-dark">{Keys.TURN_OFF_SERVER}</label>
                    <input type="checkbox" name="serverActive" value={serverActive} checked={serverActive} onChange={(e) => setServerActive(!serverActive)} className="form-check-input" />
                </div>
                <div className="d-flex felx-row align-items-center justify-content-between form-switch mt-2">
                    <label className="medium fg-dark">{Keys.TECHNICAL_DIFFICULT}</label>
                    <input type="checkbox" name="serverActive" value={serverActive} checked={serverActive} onChange={(e) => setServerActive(!serverActive)} className="form-check-input" />
                </div>
            </div>
        </div>
    );
}

export default Server;