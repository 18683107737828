import Keys from "../../../utils/constants";
import { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

const Notifications = () => {
    const [serverActive, setServerActive] = useState(true);
    
    return (
        <div className="d-flex flex-column card-16-outline m-3 p-3">
            <span className="fs-3 medium mb-2">{Keys.SETTINGS}/{Keys.NOTIFICATIONS}</span>
            <div className="d-flex flex-column bg-blue-50 rounded-3 py-4 px-4 mt-4 mt-4">
                <div className="d-flex felx-row align-items-center justify-content-between form-switch">
                    <label className="medium fg-dark">{Keys.ALL_NOTIFICATION}</label>
                    <input type="checkbox" name="serverActive" value={serverActive} checked={serverActive} onChange={(e) => setServerActive(!serverActive)} className="form-check-input" />
                </div>
                <div className="d-flex felx-row align-items-center justify-content-between form-switch mt-2">
                    <label className="medium fg-dark">{Keys.CHAT_NOTIFICATION}</label>
                    <input type="checkbox" name="serverActive" value={serverActive} checked={serverActive} onChange={(e) => setServerActive(!serverActive)} className="form-check-input" />
                </div>
            </div>
        </div>
    );
}

export default Notifications;