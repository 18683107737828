import { useEffect, useState } from 'react';
import { useParams } from "react-router";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faSquareCaretLeft } from '@fortawesome/free-solid-svg-icons';
import AxiosHelper from '../../api/AxiosHelper';
import Keys from '../../utils/constants';
import Info from './info';
import Update from './update';

const Main = () => {
    const params = useParams();
    
    const [employee, setEmployee] = useState({});
    const [toggleBtn, setToggleBtn] = useState({flag: true, class: 'btn-outline-success', text: Keys.EDIT, icon: faPenToSquare});

    useEffect(() => {
        getEmployee();
    }, [params.id]);

    const getEmployee = () => {
        AxiosHelper.getEmployee(params.id).then((res) => {
            if (res.status == 200) {
                setEmployee(res.data.data);
            }
        }).catch((e) => { console.log(e); });
    }

    const toggleEmployeeUi = () => {
        !toggleBtn.flag ?
        setToggleBtn({flag: true, class: 'btn-outline-success', text: Keys.EDIT, icon: faPenToSquare})
        : setToggleBtn({flag: false, class: 'btn-outline-warning', text: Keys.BACK, icon: faSquareCaretLeft});
    }

    return (
        <div className="d-flex flex-column m-3 p-3 card-16-outline">
            <div className='d-flex align-items-center justify-content-between'>
                <span className='fs-3 bold mb-3'>{employee.fullName}</span>
                <button className={`btn card-8 bg-gradient btn-sm ${toggleBtn.class}`} onClick={toggleEmployeeUi}>
                    <FontAwesomeIcon className='me-2' icon={toggleBtn.icon}/>{toggleBtn.text}
                </button>
            </div>
            {toggleBtn.flag ? <Info employee={employee}/> : <Update employee={employee}/> }
        </div>
    );
}

export default Main;