import { FormCheck } from "react-bootstrap";

const RadioBtn = ({...props}) => {
    const items = props.items;
    return (
        <div>
            {
                items.map((item) => {
                    return(
                        <FormCheck
                            id={item.code}
                            key={item.code}
                            inline={props.inline}
                            label={item.name}
                            value={item.code}
                            name={props.group}
                            type={props.type}
                            checked={props.attr === item.code}
                            onChange={(e) => {props.onChangeAttr(e.target.value)}}/>
                    )
                })
            }
        </div>
    );
}

export default RadioBtn;