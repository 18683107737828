import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faBell, faComment, faSearch, faBars, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import Keys from "../utils/constants";
import InputField from '../components/form/InputField';
import DropdownProfile from "./DropdownProfile";
import Utils from "../utils/index";


const Header = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [find, setFind] = useState("");
    const [showBackBtn, setShowBackBtn] = useState(false);

    useEffect(() => {
        setShowBackBtn(location.pathname == '/' ? false : true);
    }, [location.pathname]);

    return (
        <div className='header'>
            <div className="d-flex align-items-center">
                <span onClick={Utils.toggleSidebar} className="menu-toggle">
                    <FontAwesomeIcon className='text-white' icon={faBars}/>
                </span>
                {showBackBtn && <span onClick={() => {navigate(-1);}} className="btn-back ms-3">
                    <FontAwesomeIcon className='text-primary me-2' icon={faChevronLeft}/>
                    {Keys.BACK}
                </span>}
            </div>
            <div className='d-flex align-items-center'>
                <InputField type={"text"} name={"find"} value={find} onChange={(e) => setFind(e.target.value)} hint={Keys.FIND} icon={faSearch} formClass="input-field-container" inputClass="input-field-outline"/>
                <Link to={"/settings"}><FontAwesomeIcon className='text-dark mx-2' icon={faGear}/></Link>
                <DropdownProfile/>
            </div>
        </div>
    );
}

export default Header;