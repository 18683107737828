import { useState, useEffect, navigate } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faEllipsisV, faEye, faPlus, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import AxiosHelper from "../../../../api/AxiosHelper";
import Keys from "../../../../utils/constants";
import InputField from "../../../../components/form/InputField";
import DropdownU from "../../../../components/ui/DropdownU";
import PaginationU from "../../../../components/ui/PaginationU";
import LimitU from "../../../../components/ui/LimitU";
import TableStd from "../../../../components/ui/TableStd";
import DataNotFound from "../../../../components/ui/DataNotFound";
import Wrapper from "../../../../layouts/Wrapper";
import ModalU from "../../../../components/ui/ModalU";
import ToastU from "../../../../components/ui/ToastU";

const Faculties = ({...props}) => {
    const [faculties, setFaculties] = useState([]);
    const [facultiesCount, setFacultiesCount] = useState(0);
    const [findFaculty, setFindFaculty] = useState("");

    const [filter, setFilter] = useState({page:1, limit:10});

    const [modalShow, setModalShow] = useState(false);
    const [facultyName, setFacultyName] = useState('');
    const [facultyCode, setFacultyCode] = useState('');

    const [modalUpdateShow, setModalUpdateShow] = useState(false);
    const [facultyToBeUpdated, setFacultyToBeUpdated] = useState({});

    const [toast, setToast] = useState(false);
    const [toastMsg, setToastMsg] = useState({});

    const modelAttrs = [ 
        {
            name: Keys.NAME,
            value: (attr) => { return(<>{attr.name}</>) }
        },
        {
            name: Keys.CODE,
            value: (attr) => { return(<>{attr.code}</>) }
        },
        { 
            name: Keys.DEPARTMENT_COUNT,
            value: (attr) => { return(<>{attr.departmentCount}</>) }
        },
        {
            name: "",
            value: (attr) => {
                const options = [
                    {
                        name: Keys.EDIT,
                        icon: faPenToSquare,
                        color: "text-dark",
                        onClick: () => {
                            setModalUpdateShow(true);
                            setFacultyToBeUpdated(attr);
                        }
                    }
                ]
                return (
                    <DropdownU className='d-flex align-items-center justify-content-center' toggleClass={"card-8-outline py-2 px-1"} icon={faEllipsisV} items={options}/>
                )
            }
        }
    ];

    useEffect(() => {
        getFaculties();
    }, [filter, toast]);

    const getFaculties = () => {
        AxiosHelper.getFaculties(filter).then((res) => {
            if (res.status == 200) {
                setFaculties(res.data.data);
                setFacultiesCount(res.data.count);
            }
        }).catch((e) => { console.log(e); });
    }

    const createFaculty = () => {
        if (facultyName == '' || facultyCode == '') return;

        const data = { name: facultyName, code: facultyCode }
        AxiosHelper.createFaculty(data).then((res) => {
            if (res.status == 200) {
                setModalShow(false);
                setFaculties([...faculties, data]);
                setToastMsg({title: Keys.SUCCESS, msg: res.data.msg, variant: 'success'});
                setToast(true);
            }
        }).catch((err) => {
            if (!err?.response) setToastMsg({title: Keys.WARNING, msg: Keys.NO_SERVER_RESPONSE, variant: 'danger'});
            else setToastMsg({title: Keys.WARNING, msg: err.response.data.msg, variant: 'danger'});
            setToast(true);
        });
    }

    const updateFaculty = () => {
        const data = { name: facultyToBeUpdated.name, code: facultyToBeUpdated.code }

        AxiosHelper.updateFaculty(facultyToBeUpdated.id, data).then((res) => {
            if (res.status == 200) {
                setModalUpdateShow(false);
                setToastMsg({title: Keys.SUCCESS, msg: res.data.msg, variant: 'success'});
                setToast(true);
            }
        }).catch((err) => {
            if (!err?.response) setToastMsg({title: Keys.WARNING, msg: Keys.NO_SERVER_RESPONSE, variant: 'danger'});
            else setToastMsg({title: Keys.WARNING, msg: err.response.data.msg, variant: 'danger'});
            setToast(true);
        });
    }

    return (
        <Wrapper>
            <div className="d-flex flex-column m-3 p-3 card-16-outline">
                <div className='d-flex align-items-center justify-content-between'>
                    <span className='fs-2 bold mb-3'>{Keys.FACULTIES}</span>
                    <button onClick={() => setModalShow(true)} className='btn card-8 btn-outline-success bg-gradient btn-sm'>
                        <FontAwesomeIcon className='me-2' icon={faPlus}/>{Keys.CREATE_NEW}
                    </button>
                    <ModalU show={modalShow} title={Keys.FACULTY} actionbtnvariant={"primary"} actionbtntext={Keys.SAVE} onSubmit={createFaculty} onHide={() => setModalShow(false)}>
                        <input type="text" name="name" value={facultyName} onChange={(e) => setFacultyName(e.target.value)} className="form-control" placeholder={Keys.NAME} required/>
                        <input type="text" name="code" value={facultyCode} onChange={(e) => setFacultyCode(e.target.value)} className="form-control mt-2" placeholder={Keys.CODE} required/>
                    </ModalU>
                </div>
                { facultiesCount ?
                    <div className='px-3 pt-3 bg-white rounded-3'>
                        <InputField type={"text"} name={"find_faculty"} value={findFaculty} onChange={(e) => setFaculties(e.target.value)} hint={Keys.FIND} icon={faSearch} formClass="input-field-container" inputClass="input-field bg-light"/> 
                        <div className="mt-3"></div>
                        <TableStd modelAttrs={modelAttrs} data={faculties}/>
                        <div className='col-12 d-flex align-items-center justify-content-between'>
                            <LimitU size={"sm"} filter={filter} setFilter={setFilter}/>
                            <PaginationU filter={filter} setFilter={setFilter} count={facultiesCount} />
                        </div>
                    </div>
                    : <DataNotFound/>
                }
            </div>
            <ToastU show={toast} setShow={setToast} variant={toastMsg.variant} title={toastMsg.title} time={Keys.NOW} content={toastMsg.msg}/>
            <ModalU show={modalUpdateShow} title={Keys.FACULTY} actionbtnvariant={"success"} actionbtntext={Keys.SAVE} onSubmit={updateFaculty} onHide={() => setModalUpdateShow(false)}>
                <input type="text" name="name" value={facultyToBeUpdated.name} onChange={(e) => setFacultyToBeUpdated({...facultyToBeUpdated, name: e.target.value})} className="form-control" placeholder={Keys.NAME} required/>
                <input type="text" name="code" value={facultyToBeUpdated.code} onChange={(e) => setFacultyToBeUpdated({...facultyToBeUpdated, code: e.target.value})} className="form-control mt-2" placeholder={Keys.CODE} required/>
            </ModalU>
        </Wrapper>
    );
}

export default Faculties;
