import Keys from "../utils/constants";
import NavBar from "./NavBar";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUniregistry} from '@fortawesome/free-brands-svg-icons'


const SideBar = (props) => {
    const logo = require('../assets/logo_light.png');

    return (
        <nav className="sidebar">
            <div>
                <Link to={'/'} className="d-flex align-items-center no-decor mx-2 mb-5">
                    <FontAwesomeIcon className='text-white logo-sm me-2 py-3' icon={faUniregistry}/>
                    <span className="fs-4 text-dark bold">{Keys.APP_NAME}</span>
                </Link>
                <NavBar/>
            </div>
        </nav>
    );
}

export default SideBar;