import { useState } from "react";
import ReactApexChart from 'react-apexcharts';

const ApexChart = ({...props}) => {
  const series_ = [{
    name: 'Gatnaşygy',
    data: [89, 60, 86, 75, 58, 67, 82, 70, 65]
  }];

  const options_ = {
    annotations: {
      points: [{
        x: 'Bilim',
        seriesIndex: 0,
        label: {
          borderColor: '#775DD0',
          offsetY: 0,
          style: {
            color: '#fff',
            background: '#775DD0',
          },
          text: 'Bilim',
        }
      }]
    },
    chart: {
      height: 250,
      type: 'bar',
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        columnWidth: '50%',
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: 2
    },
    
    grid: {
      row: {
        colors: ['#fff', '#f2f2f2']
      }
    },
    xaxis: {
      title: {
        text: 'Fakultetler',
      },
      labels: {
        rotate: -45
      },
      categories: ['Bilim', 'Injener', 'Lukmançylyk', 'Mugallymçylyk', 'Edebiýat', 'Ykdysadyýet', 'Tehnologiýa', 'Binagärlik', 'Biznes'
      ],
      tickPlacement: 'on'
    },
    yaxis: {
      title: {
        text: 'Gatnaşygy',
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        type: "horizontal",
        shadeIntensity: 0.25,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 0.85,
        opacityTo: 0.85,
        stops: [50, 0, 100]
      },
    }
  }

  const [series, setSeries] = useState(series_);
  const [options, setOptions] = useState(options_);

  return (
    <div className="col-md-12" id="chart">
      <ReactApexChart options={options} series={series} type="bar" height={250} />
    </div>
  );
}

export default ApexChart;