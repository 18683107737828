import axios from 'axios';

const IS_DEV = false;
const DEV_URL = 'http://localhost:4000';
const PRO_URL = 'https://universitet.alemtilsimat.com/api';

const baseURL = IS_DEV ? DEV_URL : PRO_URL;

const instance = axios.create({
    baseURL,
    timeout: 20000,
    headers: { Accept: 'application/json'},
    withCredentials: true,
});

class AxiosHelper {
    static async login(data, token) {
        return await instance.post('/auth/login', data, {
            headers: { token },
        });
    }

    static async sendOTP(data, token) {
        return await instance.put('/auth/send-otp', data, {
            headers: { token },
        });
    }

    static async confirmPhone(data, token) {
        return await instance.post('/auth/confirm-phone', data, {
            headers: { token },
        });
    }

    static async logout() {
        return await instance.post('/auth/logout');
    }

    static async signUp(data) {
        return await instance.post('/auth/signup', data);
    }

    static async getUsers(filter) {
        return await instance.get('/users', {
            params:{
                filter: filter,
                sort: filter.sort,
                limit: filter.limit,
                page: filter.page
            },
        });
    }

    static async getUser(id) {
        return await instance.get('/users/' + id);
    }

    static async createUser(data) {
        return await instance.post('/users/create', data);
    }

    static async updateUser(id, data) {
        return await instance.put('/users/' + id, data);
    }
    
    static async deleteUser(id) {
        return await instance.delete('/users/' + id);
    }

    static async getStudents(filter) {
        return await instance.get('/students', {
            params:{
                filter: filter,
                sort: filter.sort,
                limit: filter.limit,
                page: filter.page
            },
        });
    }

    static async getStudent(id) {
        return await instance.get('/students/' + id);
    }

    static async createStudent(data) {
        return await instance.post('/students/create', data);
    }

    static async updateStudent(id, data) {
        return await instance.put('/students/' + id, data);
    }
    
    static async deleteStudent(id) {
        return await instance.delete('/students/' + id);
    }
    
    static async getFaculties(filter) {
	console.log('fakultetler axios');
        return await instance.get('/faculties', {
            params:{
                filter: filter,
                sort: filter.sort,
                limit: filter.limit,
                page: filter.page
            },
        });
    }

    static async createFaculty(data) {
        return await instance.post('/faculties/create', data);
    }

    static async updateFaculty(id, data) {
        return await instance.put('/faculties/' + id, data);
    }

    static async getDepartments(filter) {
        return await instance.get('/departments', {
            params:{
                filter: filter,
                sort: filter.sort,
                limit: filter.limit,
                page: filter.page
            },
        });
    }

    static async createDepartment(data) {
        return await instance.post('/departments/create', data);
    }

    static async updateDepartment(id, data) {
        return await instance.put('/departments/' + id, data);
    }

    static async getPositions(filter) {
        return await instance.get('/positions', {
            params:{
                filter: filter,
                sort: filter.sort,
                limit: filter.limit,
                page: filter.page
            },
        });
    }

    static async createPosition(data) {
        return await instance.post('/positions/create', data);
    }

    static async updatePosition(id, data) {
        return await instance.put('/positions/' + id, data);
    }
}

export default AxiosHelper;
