import { useState, useEffect, navigate } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faEllipsisV, faEye, faPlus, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import AxiosHelper from "../../../../api/AxiosHelper";
import Keys from "../../../../utils/constants";
import InputField from "../../../../components/form/InputField";
import DropdownU from "../../../../components/ui/DropdownU";
import PaginationU from "../../../../components/ui/PaginationU";
import LimitU from "../../../../components/ui/LimitU";
import TableStd from "../../../../components/ui/TableStd";
import DataNotFound from "../../../../components/ui/DataNotFound";
import Wrapper from "../../../../layouts/Wrapper";
import ModalU from "../../../../components/ui/ModalU";
import ToastU from "../../../../components/ui/ToastU";

const Positions = ({...props}) => {
    const [positions, setPositions] = useState([]);
    const [positionsCount, setPositionsCount] = useState(0);
    const [findPosition, setPosition] = useState("");

    const [filter, setFilter] = useState({page:1, limit:10});

    const [modalShow, setModalShow] = useState(false);
    const [positionName, setPositionName] = useState('');

    const [modalUpdateShow, setModalUpdateShow] = useState(false);
    const [positionToBeUpdated, setPositionToBeUpdated] = useState({});

    const [toast, setToast] = useState(false);
    const [toastMsg, setToastMsg] = useState({});

    const modelAttrs = [ 
        {
            name: Keys.NAME,
            value: (attr) => { return(<>{attr.name}</>) }
        },
        {
            name: "",
            value: (attr) => {
                const options = [
                    {
                        name: Keys.EDIT,
                        icon: faPenToSquare,
                        color: "text-dark",
                        onClick: () => {
                            setModalUpdateShow(true);
                            setPositionToBeUpdated(attr);
                        }
                    }
                ]
                return (
                    <DropdownU className='d-flex align-items-center justify-content-center' toggleClass={"card-8-outline py-2 px-1"} icon={faEllipsisV} items={options}/>
                )
            }
        }
    ];

    useEffect(() => {
        getPositions();
    }, [filter, toast]);

    const getPositions = () => {
        AxiosHelper.getPositions(filter).then((res) => {
            if (res.status == 200) {
                setPositions(res.data.data);
                setPositionsCount(res.data.count);
            }
        }).catch((e) => { console.log(e); });
    }

    const createPosition = () => {
        if (positionName == '') return;

        const data = { name: positionName }
        AxiosHelper.createPosition(data).then((res) => {
            if (res.status == 200) {
                setModalShow(false);
                setPositions([...positions, data]);
                setToastMsg({title: Keys.SUCCESS, msg: res.data.msg, variant: 'success'});
                setToast(true);
            }
        }).catch((err) => {
            if (!err?.response) setToastMsg({title: Keys.WARNING, msg: Keys.NO_SERVER_RESPONSE, variant: 'danger'});
            else setToastMsg({title: Keys.WARNING, msg: err.response.data.msg, variant: 'danger'});
            setToast(true);
        });
    }

    const updatePosition = () => {
        const data = { name: positionToBeUpdated.name }

        AxiosHelper.updatePosition(positionToBeUpdated.id, data).then((res) => {
            if (res.status == 200) {
                setModalUpdateShow(false);
                setToastMsg({title: Keys.SUCCESS, msg: res.data.msg, variant: 'success'});
                setToast(true);
            }
        }).catch((err) => {
            if (!err?.response) setToastMsg({title: Keys.WARNING, msg: Keys.NO_SERVER_RESPONSE, variant: 'danger'});
            else setToastMsg({title: Keys.WARNING, msg: err.response.data.msg, variant: 'danger'});
            setToast(true);
        });
    }

    return (
        <Wrapper>
            <div className="d-flex flex-column m-3 p-3 card-16-outline">
                <div className='d-flex align-items-center justify-content-between'>
                    <span className='fs-2 bold mb-3'>{Keys.POSITIONS}</span>
                    <button onClick={() => setModalShow(true)} className='btn card-8 btn-outline-success bg-gradient btn-sm'>
                        <FontAwesomeIcon className='me-2' icon={faPlus}/>{Keys.CREATE_NEW}
                    </button>
                    <ModalU show={modalShow} title={Keys.POSITION} actionbtnvariant={"primary"} actionbtntext={Keys.SAVE} onSubmit={createPosition} onHide={() => setModalShow(false)}>
                        <input type="text" name="name" value={positionName} onChange={(e) => setPositionName(e.target.value)} className="form-control" placeholder={Keys.NAME} required/>
                    </ModalU>
                </div>
                { positionsCount ?
                    <div className='px-3 pt-3 bg-white rounded-3'>
                        <InputField type={"text"} name={"find_position"} value={findPosition} onChange={(e) => setPositions(e.target.value)} hint={Keys.FIND} icon={faSearch} formClass="input-field-container" inputClass="input-field bg-light"/> 
                        <div className="mt-3"></div>
                        <TableStd modelAttrs={modelAttrs} data={positions}/>
                        <div className='col-12 d-flex align-items-center justify-content-between'>
                            <LimitU size={"sm"} filter={filter} setFilter={setFilter}/>
                            <PaginationU filter={filter} setFilter={setFilter} count={positionsCount} />
                        </div>
                    </div>
                    : <DataNotFound/>
                }
            </div>
            <ToastU show={toast} setShow={setToast} variant={toastMsg.variant} title={toastMsg.title} time={Keys.NOW} content={toastMsg.msg}/>
            <ModalU show={modalUpdateShow} title={Keys.POSITION} actionbtnvariant={"success"} actionbtntext={Keys.SAVE} onSubmit={updatePosition} onHide={() => setModalUpdateShow(false)}>
                <input type="text" name="name" value={positionToBeUpdated.name} onChange={(e) => setPositionToBeUpdated({...positionToBeUpdated, name: e.target.value})} className="form-control" placeholder={Keys.NAME} required/>
            </ModalU>
        </Wrapper>
    );
}

export default Positions;