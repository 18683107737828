import { useState } from "react";
import ReactApexChart from 'react-apexcharts';

const ApexChart = ({...props}) => {
  const series_ = [{
    name: 'Gatnaşygy',
    data: [95, 78, 91, 62, 71, 83, 59, 68]
  }];

  const options_ = {
    chart: {
      type: 'bar',
      height: 380
    },
    plotOptions: {
      bar: {
        barHeight: '100%',
        distributed: true,
        horizontal: true,
        dataLabels: {
          position: 'bottom'
        },
      }
    },
    colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e', '#f48024', '#69d2e7'],
    dataLabels: {
      enabled: true,
      textAnchor: 'start',
      style: {
        colors: ['#fff']
      },
      formatter: function (val, opt) {
        return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
      },
      offsetX: 0,
      dropShadow: {
        enabled: true
      }
    },
    stroke: {
      width: 1,
      colors: ['#fff']
    },
    xaxis: {
      categories: ['Matematika', 'Fizika', 'Himiýa', 'Sanly Ulgam', 'Taryh', 'Türkmen Dili', 'Binagärlik',
        'Dizaýn', 'Gurluşyk', 'Elektrik'
      ],
    },
    yaxis: {
      labels: {
        show: false
      }
    },
    title: {
        text: 'Bölümler boýynça yetişikler',
        align: 'center',
        floating: true
    },
    tooltip: {
      theme: 'dark',
      x: {
        show: false
      },
      y: {
        title: {
          formatter: function () {
            return ''
          }
        }
      }
    }
  }
    

  const [series, setSeries] = useState(series_);
  const [options, setOptions] = useState(options_);

  return (
    <div className="col-md-12" id="chart">
      <ReactApexChart options={options} series={series} type="bar" height={400} />
    </div>
  );
}

export default ApexChart;