import { useState, useEffect } from "react";
import AxiosHelper from "../../api/AxiosHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Keys from "../../utils/constants";
import { faBuildingColumns, faGraduationCap, faMoneyCheck, faSackDollar, faUser, faUsers, } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import CardCountItem from "./CardCountItem";
import ChartByFaculty from '../../components/charts/ChartByFaculty';
import ChartByDepartment from '../../components/charts/ChartByDepartment';

const Main = () => {
    const cardCountItems = [
        { bg: 'bg-red-400', icon: faGraduationCap, name: Keys.STUDENTS, count: 1350 },
        { bg: 'bg-blue-400', icon: faUser, name: Keys.TEACHERS, count: 160 },
        { bg: 'bg-green-400', icon: faBuildingColumns, name: Keys.DEPARTMENTS, count: 31 },
        { bg: 'bg-yellow-400', icon: faBuildingColumns, name: Keys.FACULTIES, count: 9 }
    ]

    return (
        <div className="d-flex flex-column justify-content-center px-4">
            <div className="row card-16-outline d-flex flex-row align-items-center justify-content-around my-3 py-2">
                {
                    cardCountItems.map((item) => {
                        return(<CardCountItem key={item.name} item={item}/>)
                    })
                }
            </div>
            <div className="row card-16-outline d-flex flex-column p-4">
                <ChartByFaculty />
                <div className="mt-3"></div>
                <ChartByDepartment />
            </div>
        </div>
    );
}

export default Main;