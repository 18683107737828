import { useEffect } from "react";
import { useState } from "react";
import { Pagination } from "react-bootstrap";

const PaginationU = ({...props}) => {
    const [max, setMax] = useState(0);

    useEffect(() => {
        setMax(Math.round(props.count/props.filter.limit));
    }, [props.count, props.filter]);

    return (
        <Pagination>
            <Pagination.First onClick={() => {
                if (props.filter.page > 1) props.setFilter({...props.filter, page: 1});
            }}/>
            <Pagination.Prev onClick={() => {
                if (props.filter.page > 1) props.setFilter({...props.filter, page: props.filter.page - 1});
            }}/>
            {
                Array.apply(1, Array(max)).map((x, i) => {
                    return (
                        <Pagination.Item onClick={(e) => {props.setFilter({...props.filter, page: (i+1)})}} key={i}>
                            {(i+1)}
                        </Pagination.Item>
                    );
                })
            }
            <Pagination.Next onClick={() => {
                if (props.filter.page < max) props.setFilter({...props.filter, page: props.filter.page + 1});
            }}/>
            <Pagination.Last onClick={() => {
                if (props.filter.page < max) props.setFilter({...props.filter, page: max});
            }}/>
        </Pagination>
    );
}

export default PaginationU;