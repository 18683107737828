import { FormSelect } from "react-bootstrap";

const LimitU = ({...props}) => {
    const limits = [10, 20, 30, 40];
    return (
        <div>
            <FormSelect size={props.size} value={props.filter.limit} onChange={(e) => {props.setFilter({...props.filter, limit:e.target.value})}}>
                {
                    limits.map((item) => {
                        return ( <option value={item} key={item}>{item}</option>)
                    })
                }
            </FormSelect>
        </div>
    );
}

export default LimitU;