import { useEffect, useRef, useState } from "react";
import Keys from "../../utils/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import AxiosHelper from "../../api/AxiosHelper";
import ToastU from "../../components/ui/ToastU";

const Label = ({...props}) => {
    return (
        <label htmlFor={props.htmlFor} className="d-flex form-label fg-dark bold">
            {props.label}
            <div className="ms-2">
                <FontAwesomeIcon className={props.validAttr && props.attr2 ? "valid" : "hide"} icon={faCheckCircle}/>
                <FontAwesomeIcon className={props.validAttr || !props.attr ? "hide" : "invalid"} icon={faTimesCircle}/>
            </div>
        </label>
    );
}

const Instructions = ({...props}) => {
    return (
        <p id={props.id} className={props.attrFocus && props.attr && !props.validAttr ? "instructions" : "offscreen"}>
            <FontAwesomeIcon className="me-2" icon={faInfoCircle} />
            {props.attrInstruction}
        </p>
    );
}

const SignUp = () => {  
    const usernameRef = useRef();

    const [username, setUsername] = useState('');
    const [validUsername, setValidUsername] = useState(false);
    const [usernameFocus, setUsernameFocus] = useState(false);

    const [firstName, setFirstName] = useState('');
    const [validFirstName, setValidFirstName] = useState(false);
    const [firstNameFocus, setFirstNameFocus] = useState(false);

    const [lastName, setLastName] = useState('');
    const [validLastName, setValidLastName] = useState(false);
    const [lastNameFocus, setLastNameFocus] = useState(false);

    const [middleName, setMiddleName] = useState('');
    const [validMiddleName, setValidMiddleName] = useState(false);
    const [middleNameFocus, setMiddleNameFocus] = useState(false);

    const [phoneNumber, setPhoneNumber] = useState('');
    const [validPhoneNumber, setValidPhoneNumber] = useState(false);
    const [phoneNumberFocus, setPhoneNumberFocus] = useState(false);

    const [password, setPassword] = useState('');
    const [validPassword, setValidPassword] = useState(false);
    const [passwordFocus, setPasswordFocus] = useState(false);

    const [matchPassword, setMatchPassword] = useState('');
    const [validMatchPassword, setValidMatchPassword] = useState(false);
    const [matchPasswordFocus, setMatchPasswordFocus] = useState(false);

    const [toast, setToast] = useState(false);
    const [toastMsg, setToastMsg] = useState('');

    useEffect(() => {
        usernameRef.current.focus();
    }, []);

    useEffect(() => {
        setValidUsername(Keys.USERNAME_REGEX.test(username));
    }, [username]);

    useEffect(() => {
        setValidFirstName(Keys.NAME_REGEX.test(firstName));
    }, [firstName]);

    useEffect(() => {
        setValidLastName(Keys.NAME_REGEX.test(lastName));
    }, [lastName]);

    useEffect(() => {
        setValidMiddleName(Keys.NAME_REGEX.test(middleName));
    }, [middleName]);

    useEffect(() => {
        setValidPhoneNumber(Keys.PHONE_NUMBER_REGEX.test(phoneNumber));
    }, [phoneNumber]);

    useEffect(() => {
        setValidPassword(Keys.PASSWORD_REGEX.test(password));
        const match = (password === matchPassword);
        setValidMatchPassword(match);
    }, [password, matchPassword]);

    useEffect(() => {
        setToastMsg('');
    }, [username, firstName, lastName, middleName, phoneNumber, password, matchPassword]);

    const handleSubmit = (event) => {
        event.preventDefault();

        const v1 = Keys.USERNAME_REGEX.test(username);
        const v2 = Keys.PASSWORD_REGEX.test(password);
        const v3 = Keys.NAME_REGEX.test(firstName);
        const v4 = Keys.NAME_REGEX.test(lastName);
        const v5 = Keys.NAME_REGEX.test(middleName);
        const v6 = Keys.PHONE_NUMBER_REGEX.test(phoneNumber);

        if (!v1 || !v2 || !v3 || !v4 || (!v5 && middleName) || !v6) {
            setToastMsg(Keys.INVALID_ENTRY);
            setToast(true);
            return;
        }

        const data = { username, firstName, lastName, middleName, phoneNumber, password };
        
        AxiosHelper.signUp(data).then((res) => {
            if (res.status == 200) window.location.reload();
        }).catch((err) => {
            if (!err?.response) setToastMsg(Keys.NO_SERVER_RESPONSE);
            else setToastMsg(err.response.data.msg);
            setToast(true);
        });
    }

    return (
        <div className="container col-md-6 border rounded-3 p-4 bg-white">
            <ToastU show={toast} setShow={setToast} variant={'danger'} title={Keys.ERROR} time={Keys.NOW} content={toastMsg}/>
            <form className="row" onSubmit={handleSubmit} noValidate>
                <div className="col-md-6 mb-2">
                    <Label htmlFor={"username"} label={Keys.USERNAME} validAttr={validUsername} attr={username} attr2={true}/>
                    <input
                        id="username"
                        ref={usernameRef}
                        type="text"
                        name="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        className="form-control bg-light"
                        placeholder={Keys.USERNAME}
                        autoComplete="off"
                        aria-invalid={validUsername ? "false" : "true"}
                        aria-describedby="uid_note"
                        onFocus={() => setUsernameFocus(true)}
                        onBlur={() => setUsernameFocus(false)}
                        required/>
                    <Instructions id={"uid_note"} attrFocus={usernameFocus} attr={username} validAttr={validUsername} attrInstruction={Keys.USERNAME_INSTRUCTION}/>
                </div>
                
                <div className="col-md-6 mb-2">
                    <Label htmlFor={"phoneNumber"} label={Keys.PHONE_NUMBER} validAttr={validPhoneNumber} attr={phoneNumber} attr2={true}/>
                    <input
                        id="phoneNumber"
                        type="number"
                        name="phoneNumber"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        className="form-control bg-light"
                        placeholder={Keys.PHONE_NUMBER}
                        autoComplete="off"
                        aria-invalid={validPhoneNumber ? "false" : "true"}
                        aria-describedby="phoneNumber_note"
                        onFocus={() => setPhoneNumberFocus(true)}
                        onBlur={() => setPhoneNumberFocus(false)}
                        required/>
                    <Instructions id={"phoneNumber_note"} attrFocus={phoneNumberFocus} attr={phoneNumber} validAttr={validPhoneNumber} attrInstruction={Keys.PHONE_NUMBER_INSTRUCTION}/>
                </div>

                <div className="col-md-6 mb-2">
                    <Label htmlFor={"firstName"} label={Keys.FIRSTNAME} validAttr={validFirstName} attr={firstName} attr2={true}/>
                    <input
                        id="firstName"
                        type="text"
                        name="firstName"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        className="form-control bg-light"
                        placeholder={Keys.FIRSTNAME}
                        autoComplete="off"
                        aria-invalid={validFirstName ? "false" : "true"}
                        aria-describedby="firstName_note"
                        onFocus={() => setFirstNameFocus(true)}
                        onBlur={() => setFirstNameFocus(false)}
                        required/>
                    <Instructions id={"firstName_note"} attrFocus={firstNameFocus} attr={firstName} validAttr={validFirstName} attrInstruction={Keys.NAME_INSTRUCTION}/>
                </div>

                <div className="col-md-6 mb-2">
                    <Label htmlFor={"lastName"} label={Keys.LASTNAME} validAttr={validLastName} attr={lastName} attr2={true}/>
                    <input
                        id="lastName"
                        type="text"
                        name="lastName"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        className="form-control bg-light"
                        placeholder={Keys.LASTNAME}
                        autoComplete="off"
                        aria-invalid={validLastName ? "false" : "true"}
                        aria-describedby="lastName_note"
                        onFocus={() => setLastNameFocus(true)}
                        onBlur={() => setLastNameFocus(false)}
                        required/>
                    <Instructions id={"lastName_note"} attrFocus={lastNameFocus} attr={lastName} validAttr={validLastName} attrInstruction={Keys.NAME_INSTRUCTION}/>
                </div>

                <div className="col-md-6 mb-2">
                    <Label htmlFor={"middleName"} label={Keys.MIDDLENAME} validAttr={validMiddleName} attr={middleName} attr2={true}/>
                    <input
                        id="middleName"
                        type="text"
                        name="middleName"
                        value={middleName}
                        onChange={(e) => setMiddleName(e.target.value)}
                        className="form-control bg-light"
                        placeholder={Keys.MIDDLENAME}
                        autoComplete="off"
                        aria-invalid={validMiddleName ? "false" : "true"}
                        aria-describedby="middleName_note"
                        onFocus={() => setMiddleNameFocus(true)}
                        onBlur={() => setMiddleNameFocus(false)}
                        required/>
                    <Instructions id={"middleName_note"} attrFocus={middleNameFocus} attr={middleName} validAttr={validMiddleName} attrInstruction={Keys.NAME_INSTRUCTION}/>
                </div>

                <div className="col-md-6"></div>

                <div className="col-md-6 mb-2">
                    <Label htmlFor={"password"} label={Keys.PASSWORD} validAttr={validPassword} attr={password} attr2={true}/>
                    <input
                        id="password"
                        type="password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="form-control bg-light"
                        placeholder={Keys.PASSWORD}
                        aria-invalid={validPassword ? "false" : "true"}
                        aria-describedby="password_note"
                        onFocus={() => setPasswordFocus(true)}
                        onBlur={() => setPasswordFocus(false)}
                        required/>
                    <Instructions id={"password_note"} attrFocus={passwordFocus} attr={password} validAttr={validPassword} attrInstruction={Keys.PASSWORD_INSTRUCTION}/>
                </div>

                <div className="col-md-6 mb-2">
                    <Label htmlFor={"confirm_password"} label={Keys.CONFIRM_PASSWORD} validAttr={validMatchPassword} attr={matchPassword} attr2={matchPassword}/>
                    <input
                        id="confirm_password"
                        type="password"
                        name="password"
                        value={matchPassword}
                        onChange={(e) => setMatchPassword(e.target.value)}
                        className="form-control bg-light"
                        placeholder={Keys.CONFIRM_PASSWORD}
                        aria-invalid={validPassword ? "false" : "true"}
                        aria-describedby="confirm_password_note"
                        onFocus={() => setMatchPasswordFocus(true)}
                        onBlur={() => setMatchPasswordFocus(false)}
                        required/>
                    <Instructions id={"confirm_password_note"} attrFocus={matchPasswordFocus} attr={true} validAttr={validMatchPassword} attrInstruction={Keys.MATCH_PASSWORD_INSTRUCTION}/>
                </div>
                <button
                    type="submit"
                    className="btn btn-primary m-auto bg-gradient mt-3 col-4"
                    disabled={(!validUsername || !validPassword || !validMatchPassword || !validFirstName || !validLastName || !validPhoneNumber) ? true : false}>
                    {Keys.SIGN_UP}
                </button>
            </form>
        </div>
    );
}

export default SignUp;