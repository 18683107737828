import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './pages/Login';
import Home from './pages/Home';
import Profile from './pages/Profile';
import Settings from './pages/Settings';
import Users from './pages/Users';
import User from './pages/User';
import NewUser from './pages/Users/NewUser';
import Information from './pages/Information';
import Faculties from './pages/Information/Tabs/Common/Faculties';
import Departments from './pages/Information/Tabs/Common/Departments';
import Positions from './pages/Information/Tabs/Common/Positions';
import Students from './pages/Students';
import Statistics from './pages/Statistics';
import NewStudent from './pages/Students/NewStudent';

function App() {
  
  return (
    <BrowserRouter>
        <Routes>
          <Route path='/'element={<Home />} />
          <Route path='/auth/login' element={<Login />} />
          <Route path='/profile' element={<Profile />} />
          <Route path='/settings' element={<Settings />} />
          <Route path='/users' element={<Users />} />
          <Route path='/users/:id' element={<User/>}/>
          <Route path='/users/create' element={<NewUser/>}/>
          <Route path='/informations' element={<Information/>}/>
          <Route path='/faculties' element={<Faculties/>}/>
          <Route path='/departments' element={<Departments/>}/>
          <Route path='/positions' element={<Positions/>}/>
          <Route path='/students' element={<Students/>}/>
          <Route path='/students/:id' element={<User/>}/>
          <Route path='/students/create' element={<NewStudent/>}/>
          <Route path='/statistics' element={<Statistics/>}/>
        </Routes>
      </BrowserRouter>
  );
}

export default App;