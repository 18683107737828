import Utils from '../../utils';
import Keys from '../../utils/constants';

const Info = ({...props}) => {
    const user = props.user;

    return (
        <div>
            <div className='d-flex p-3 bg-white rounded-3'>
                <div className='d-flex flex-column align-items-center py-4 px-5 border rounded-2'>
                    <span className='avatar border border-light p-5 bold fs-1 bg-yellow-100 text-black'>{Utils.getAvatar(user)}</span>
                    <span className='fs-5 mt-2 medium'>{user.departmentName}</span>
                </div>
                <div className='d-flex flex-column ms-5'>
                    <span className='fg-dark'><b>{Keys.PHONE_NUMBER}</b>: {user.phoneNumber}</span>
                    <span className='fg-dark'><b>{Keys.FACULTY}/{Keys.DEPARTMENT}</b>: {user.facultyName}/{user.departmentName}</span>
                    <span className='fg-dark'><b>{Keys.REGISTRATION_DATE}</b>: {Utils.getTimestampDate(user.createdAt)}</span>
                    <span className='fg-dark'><b>{Keys.ADDRESS}</b>: Magtymguly köçesi, Aşgabat şäheri</span>
                </div>
            </div>
        </div>
    );
}

export default Info;