import { useEffect, useRef, useState } from "react";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from 'react-router-dom';
import AxiosHelper from "../../api/AxiosHelper";
import Keys from "../../utils/constants";
import ToastU from "../../components/ui/ToastU";

const Login = () => {
    const navigate = useNavigate();
    const usernameRef = useRef();

    const { auth, setAuth } = useAuth();
    let loggedIn = false;
    try { loggedIn = !!auth.token } catch(e) { loggedIn = false };

    const [tempAuth, setTempAuth] = useState([]);
    const [username, setUsername] = useState(''); 
    const [password, setPassword] = useState('');
    const [otpCode, setOtpCode] = useState('');
    const [counter, setCounter] = useState(60);
    const [toast, setToast] = useState(false);
    const [toastMsg, setToastMsg] = useState('');

    const [showSigninForm, setShowSigninForm] = useState(true);
    const [showOtpCodeForm, setShowOtpCodeForm] = useState(false);

    useEffect(() => {
        usernameRef.current.focus();
    }, []);

    useEffect(() => {
        setToastMsg('');
    }, [username, password]);

    useEffect(() => {
        if (loggedIn) {
            navigate("/");
        }
        setTimeout(() => {
            setCounter(counter - 1);
        }, 1000);
    }, [auth, counter]);

    const handleLoginSubmit = (event) => {
        event.preventDefault();

        const data = { username, password }
        AxiosHelper.login(data, auth.token).then((res) => {
            if (res.status == 200) {
                setTempAuth(res.data);
                sendOTPCode();
            }
        }).catch((err) => {
            if (!err?.response) setToastMsg(Keys.NO_SERVER_RESPONSE);
            else setToastMsg(err.response.data.msg);
            setToast(true);
        });
    }

    function sendOTPCode() {
        const data = { username };
        AxiosHelper.sendOTP(data, auth.token).then((res) => {
            if (res.status == 200) {
                setShowSigninForm(false);
                setShowOtpCodeForm(true);
            }
        }).catch((err) => {
            if (!err?.response) setToastMsg(Keys.NO_SERVER_RESPONSE);
            else setToastMsg(err.response.data.msg);
            setToast(true);
        });
    }

    const handleOtpSubmit = (event) => {
        event.preventDefault();
        
        const data = { username, otpCode }
        AxiosHelper.confirmPhone(data, auth.token).then((res) => {
            if (res.status == 200) {
                setAuth(tempAuth);
                navigate('/');
            }
        }).catch((err) => {
            if (!err?.response) setToastMsg(Keys.NO_SERVER_RESPONSE);
            else setToastMsg(err.response.data.msg);
            setToast(true);
        });
    }

    return (
        <div className="container col-md-3 border rounded-3 p-4 bg-white">
            <ToastU show={toast} setShow={setToast} variant={'danger'} title={Keys.ERROR} time={Keys.NOW} content={toastMsg}/>
            {showSigninForm && 
                <form onSubmit={handleLoginSubmit} noValidate>
                    <div className="mb-3">
                        <label htmlFor="username" className="form-label text-dark bold">{Keys.USERNAME}</label>
                        <input
                            id="username"
                            ref={usernameRef}
                            type="text"
                            name="username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            className="form-control bg-light"
                            placeholder={Keys.YOUR_USERNAME}
                            autoComplete="off"
                            required/>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="password" className="form-label text-dark bold">{Keys.PASSWORD}</label>
                        <input
                            id="password"
                            type="password"
                            name="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="form-control bg-light"
                            placeholder={Keys.YOUR_PASSWORD}
                            required/>
                    </div>
                    <button
                        type="submit"
                        className="btn btn-primary bg-gradient col-12"
                        disabled={(!username || !password) ? true : false}>
                            {Keys.SIGN_IN}
                    </button>
                </form>
            }
            {showOtpCodeForm &&
                <div>
                    <form onSubmit={handleOtpSubmit} className="mt-3" noValidate>
                        <div className="mb-3">
                            <label className="form-label text-dark bold">{Keys.OTP_CODE}</label>
                            <input type="number" name="otpCode" value={otpCode} onChange={(e) => setOtpCode(e.target.value)} className="form-control bg-light" placeholder={Keys.X_DIGIT_CODE} required/>
                        </div>
                        <button
                            type="submit"
                            className="btn btn-success bg-gradient col-12"
                            disabled={!otpCode ? true : false}>
                            {Keys.SUBMIT}
                        </button>
                    </form>
                    <div className="d-flex mt-4 col-12 align-items-center justify-content-center">
                        {counter > 0 &&
                            <div className="d-flex badge bg-warning py-2 px-3">
                                <span className="me-2 text-dark">{Keys.REMAINING_TIME}</span>
                                <span className="text-dark">{counter}</span>
                            </div>
                        }
                        {counter <= 0 &&
                            <button className="btn btn-warning bg-gradient btn-sm px-4">{Keys.RESEND_CODE}</button>
                        }
                    </div>
                </div>
            }
        </div>
    );
};

export default Login;