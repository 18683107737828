import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Keys from "../../utils/constants";
import Wrapper from "../../layouts/Wrapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from '@fortawesome/free-solid-svg-icons'
import AxiosHelper from "../../api/AxiosHelper";
import ToastU from "../../components/ui/ToastU";
import Selectable from "../../components/ui/Selectable";
import ValidationLabel from '../../components/ui/ValidationLabel';
import ValidationInstruction from '../../components/ui/ValidationInstruction';
import userPhoto from '../../assets/user.png';
import { FormLabel } from "react-bootstrap";
import RadioBtn from "../../components/ui/RadioBtn";

const NewUser = ({...props}) => {
    const navigate = useNavigate();

    const [faculties, setFaculties] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [positions, setPositions] = useState([]);

    const [photo, setPhoto] = useState(userPhoto);

    const [firstName, setFirstName] = useState('');
    const [validFirstName, setValidFirstName] = useState(false);
    const [firstNameFocus, setFirstNameFocus] = useState(false);

    const [lastName, setLastName] = useState('');
    const [validLastName, setValidLastName] = useState(false);
    const [lastNameFocus, setLastNameFocus] = useState(false);

    const [middleName, setMiddleName] = useState('');
    const [validMiddleName, setValidMiddleName] = useState(false);
    const [middleNameFocus, setMiddleNameFocus] = useState(false);

    const [gender, setGender] = useState(Keys.ENUM_GENDER[0].code);
    
    const [phoneNumber, setPhoneNumber] = useState('');
    const [validPhoneNumber, setValidPhoneNumber] = useState(false);
    const [phoneNumberFocus, setPhoneNumberFocus] = useState(false);

    const [email, setEmail] = useState('');

    const [provinceId, setProvinceId] = useState('');
    const [districtId, setDistrictId] = useState('');

    const [address, setAddress] = useState('');

    const [facultyId, setFacultyId] = useState('');
    const [departmentId, setDepartmentId] = useState('');
    const [positionId, setPositionId] = useState('');

    const [toast, setToast] = useState(false);
    const [toastMsg, setToastMsg] = useState('');

    useEffect(() => {
        getFaculties();
        getPositions();
    }, []);

    useEffect(() => {
        getDepartments();
    }, [facultyId]);

    useEffect(() => {
        setValidFirstName(Keys.NAME_REGEX.test(firstName));
    }, [firstName]);

    useEffect(() => {
        setValidLastName(Keys.NAME_REGEX.test(lastName));
    }, [lastName]);

    useEffect(() => {
        setValidMiddleName(Keys.NAME_REGEX.test(middleName));
    }, [middleName]);

    useEffect(() => {
        setValidPhoneNumber(Keys.PHONE_NUMBER_REGEX.test(phoneNumber));
    }, [phoneNumber]);

    useEffect(() => {
        setToastMsg('');
    }, [firstName, lastName, middleName, phoneNumber]);

    const getFaculties = () => {
        AxiosHelper.getFaculties({}).then((res) => {
            if (res.status == 200) {
                setFaculties(res.data.data);
            }
        }).catch((e) => { console.log(e); });
    }

    const getDepartments = () => {
        if (!facultyId) return;
        const filter = {facultyId: facultyId};

        AxiosHelper.getDepartments(filter).then((res) => {
            if (res.status == 200) {
                setDepartments(res.data.data);
            }
        }).catch((e) => { console.log(e); });
    }

    const getPositions = () => {
        AxiosHelper.getPositions({}).then((res) => {
            if (res.status == 200) {
                setPositions(res.data.data);
            }
        }).catch((e) => { console.log(e); });
    }
    
    const createUser = () => {
        const data = { firstName, lastName, middleName, phoneNumber, facultyId, departmentId, positionId };
        
        AxiosHelper.createUser(data).then((res) => {
            if (res.status == 200) {    
                navigate(-1);
            }
        }).catch((err) => {
            if (!err?.response) setToastMsg(Keys.NO_SERVER_RESPONSE);
            else setToastMsg(err.response.data.msg);
            setToast(true);
        });
    }

    const handleFile = (e) => {
        setPhoto(URL.createObjectURL(e.target.files[0]))
    }

    const handleSubmit = () => {
        const v1 = Keys.NAME_REGEX.test(firstName);
        const v2 = Keys.NAME_REGEX.test(lastName);
        const v3 = Keys.NAME_REGEX.test(middleName);
        const v4 = Keys.PHONE_NUMBER_REGEX.test(phoneNumber);

        if (!v1 || !v2 || (!v3 && middleName) || !v4 || email == '' || address == '') {
            setToastMsg(Keys.INVALID_ENTRY);
            setToast(true);
            return;
        }

        createUser();
    }
    
    return (
        <Wrapper>
            <ToastU show={toast} setShow={setToast} variant={'danger'} title={Keys.ERROR} time={Keys.NOW} content={toastMsg}/>
            <div className="d-flex flex-column m-3 p-3 card-16-outline">
                <div className='d-flex flex-row align-items-center justify-content-between'>
                    <span className='text-dark medium fs-4'>{Keys.USER}</span>
                    <button
                        onClick={handleSubmit}
                        className="btn btn-info bg-gradient"
                        disabled={(!validFirstName || !validLastName || !validPhoneNumber) ? true : false}>
                        <FontAwesomeIcon className="me-2" icon={faSave}/>
                        {Keys.SAVE}
                    </button>
                </div>
                <div className="card-8 bg-neutral-100 border border-primary p-3 my-3">
                <form className="row" noValidate>
                        <div className="col-md-3">
                            <span className="px-3 rounded-2 bg-white"><b>{Keys.ID}:</b> {'2023102360'}</span>
                            <img src={photo} alt="" className="img-photo mb-1 pt-5 mt-2"/>
                            <label htmlFor="uploadBtn" onChange={handleFile} className="upload-btn d-flex flex-column col-md-6 mt-2">
                                {Keys.UPLOAD_PHOTO}
                                <input id="uploadBtn" type="file" hidden/>
                            </label>
                        </div>
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-4 mb-2">
                                    <ValidationLabel htmlFor={"firstName"} label={Keys.FIRSTNAME} validAttr={validFirstName} attr={firstName} attr2={true}/>
                                    <input
                                        id="firstName"
                                        type="text"
                                        name="firstName"
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        className="form-control-sm input-outline-primary col-md-12 bg-purple-50"
                                        placeholder={Keys.FIRSTNAME}
                                        autoComplete="off"
                                        aria-invalid={validFirstName ? "false" : "true"}
                                        aria-describedby="firstName_note"
                                        onFocus={() => setFirstNameFocus(true)}
                                        onBlur={() => setFirstNameFocus(false)}
                                        required/>
                                    <ValidationInstruction id={"firstName_note"} attrFocus={firstNameFocus} attr={firstName} validAttr={validFirstName} attrInstruction={Keys.NAME_INSTRUCTION}/>
                                </div>
                                <div className="col-md-4 mb-2">
                                    <ValidationLabel htmlFor={"lastName"} label={Keys.LASTNAME} validAttr={validLastName} attr={lastName} attr2={true}/>
                                    <input
                                        id="lastName"
                                        type="text"
                                        name="lastName"
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                        className="form-control-sm input-outline-primary col-md-12 bg-purple-50"
                                        placeholder={Keys.LASTNAME}
                                        autoComplete="off"
                                        aria-invalid={validLastName ? "false" : "true"}
                                        aria-describedby="lastName_note"
                                        onFocus={() => setLastNameFocus(true)}
                                        onBlur={() => setLastNameFocus(false)}
                                        required/>
                                    <ValidationInstruction id={"lastName_note"} attrFocus={lastNameFocus} attr={lastName} validAttr={validLastName} attrInstruction={Keys.NAME_INSTRUCTION}/>
                                </div>
                                <div className="col-md-4 mb-2">
                                    <ValidationLabel htmlFor={"middleName"} label={Keys.MIDDLENAME} validAttr={validMiddleName} attr={middleName} attr2={true}/>
                                    <input
                                        id="middleName"
                                        type="text"
                                        name="middleName"
                                        value={middleName}
                                        onChange={(e) => setMiddleName(e.target.value)}
                                        className="form-control-sm input-outline-primary col-md-12 bg-purple-50"
                                        placeholder={Keys.MIDDLENAME}
                                        autoComplete="off"
                                        aria-invalid={validMiddleName ? "false" : "true"}
                                        aria-describedby="middleName_note"
                                        onFocus={() => setMiddleNameFocus(true)}
                                        onBlur={() => setMiddleNameFocus(false)}
                                        required/>
                                    <ValidationInstruction id={"middleName_note"} attrFocus={middleNameFocus} attr={middleName} validAttr={validMiddleName} attrInstruction={Keys.NAME_INSTRUCTION}/>
                                </div>
                                <div className="col-md-4 mb-2">
                                    <FormLabel className="bold fg-dark">{Keys.GENDER}</FormLabel>
                                    <RadioBtn items={Keys.ENUM_GENDER} inline={true} group={'gender'} attr={gender} onChangeAttr={setGender} type={'radio'}/>
                                </div>
                                <div className="col-md-4 mb-2">
                                    <ValidationLabel htmlFor={"phoneNumber"} label={Keys.PHONE_NUMBER} validAttr={validPhoneNumber} attr={phoneNumber} attr2={true}/>
                                    <input
                                        id="phoneNumber"
                                        type="number"
                                        name="phoneNumber"
                                        value={phoneNumber}
                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                        className="form-control-sm input-outline-primary col-md-12 bg-purple-50"
                                        placeholder={Keys.PHONE_NUMBER}
                                        autoComplete="off"
                                        aria-invalid={validPhoneNumber ? "false" : "true"}
                                        aria-describedby="phoneNumber_note"
                                        onFocus={() => setPhoneNumberFocus(true)}
                                        onBlur={() => setPhoneNumberFocus(false)}
                                        required/>
                                    <ValidationInstruction id={"phoneNumber_note"} attrFocus={phoneNumberFocus} attr={phoneNumber} validAttr={validPhoneNumber} attrInstruction={Keys.PHONE_NUMBER_INSTRUCTION}/>
                                </div>
                                <div className="col-md-4 mb-2">
                                    <FormLabel className="bold fg-dark">{Keys.EMAIL}</FormLabel>
                                    <input
                                        id="email"
                                        type="email"
                                        name="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        className="form-control-sm input-outline-primary col-md-12 bg-purple-50"
                                        placeholder={Keys.EMAIL}
                                        autoComplete="off"
                                        required/>
                                </div>
                                <Selectable className={"col-md-4 my-2"} value={provinceId} setValue={setProvinceId} title={Keys.PROVINCE} items={[]}/>
                                <Selectable className={"col-md-4 my-2"} value={districtId} setValue={setDistrictId} title={Keys.DISTRICT} items={[]}/>
                                <div className="col-md-4 mb-2">
                                    <FormLabel className="bold fg-dark">{Keys.ADDRESS}</FormLabel>
                                    <input
                                        type="text"
                                        name="address"
                                        value={address}
                                        onChange={(e) => setAddress(e.target.value)}
                                        className="form-control-sm input-outline-primary col-md-12 bg-purple-50"
                                        placeholder={Keys.ADDRESS}
                                        autoComplete="off"
                                        required/>
                                </div>
                            
                                <Selectable className={"col-md-4 my-2"} value={facultyId} setValue={setFacultyId} title={Keys.FACULTY} items={faculties}/>
                                <Selectable className={"col-md-4 my-2"} value={departmentId} setValue={setDepartmentId} title={Keys.DEPARTMENT} items={departments}/>
                                <Selectable className={"col-md-4 my-2"} value={positionId} setValue={setPositionId} title={Keys.POSITION} items={positions}/>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Wrapper>
    );
}

export default NewUser;