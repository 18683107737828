import { useState, useEffect, navigate } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faEllipsisV, faEye, faPlus, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import AxiosHelper from "../../../../api/AxiosHelper";
import Keys from "../../../../utils/constants";
import InputField from "../../../../components/form/InputField";
import DropdownU from "../../../../components/ui/DropdownU";
import PaginationU from "../../../../components/ui/PaginationU";
import LimitU from "../../../../components/ui/LimitU";
import TableStd from "../../../../components/ui/TableStd";
import DataNotFound from "../../../../components/ui/DataNotFound";
import Wrapper from "../../../../layouts/Wrapper";
import ModalU from "../../../../components/ui/ModalU";
import ToastU from "../../../../components/ui/ToastU";
import Selectable from "../../../../components/ui/Selectable";

const Departments = ({...props}) => {
    const [faculties, setFaculties] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [departmentsCount, setDepartmentsCount] = useState(0);

    const [findDepartment, setFindDepartment] = useState("");
    const [filter, setFilter] = useState({page:1, limit:10});

    const [departmentName, setDepartmentName] = useState('');
    const [departmentCode, setDepartmentCode] = useState('');
    const [facultyId, setFacultyId] = useState('');
    
    const [departmentToBeUpdated, setDepartmentToBeUpdated] = useState({});
    
    const [modalShow, setModalShow] = useState(false);
    const [modalUpdateShow, setModalUpdateShow] = useState(false);

    const [toast, setToast] = useState(false);
    const [toastMsg, setToastMsg] = useState({});

    const modelAttrs = [ 
        {
            name: Keys.NAME,
            value: (attr) => { return(<>{attr.name}</>) }
        },
        {
            name: Keys.CODE,
            value: (attr) => { return(<>{attr.code}</>) }
        },
        { 
            name: Keys.FACULTY,
            value: (attr) => { return(<>{attr.facultyName}</>) }
        },
        {
            name: "",
            value: (attr) => {
                const options = [
                    {
                        name: Keys.EDIT,
                        icon: faPenToSquare,
                        color: "text-dark",
                        onClick: () => {
                            setModalUpdateShow(true);
                            setDepartmentToBeUpdated(attr);
                            setFacultyId(attr.facultyId);
                        },
                    }
                ]
                return (
                    <DropdownU className='d-flex align-items-center justify-content-center' toggleClass={"card-8-outline py-2 px-1"} icon={faEllipsisV} items={options}/>
                )
            }
        }
    ];

    const getFaculties = () => {
        AxiosHelper.getFaculties({}).then((res) => {
            if (res.status == 200) {
                setFaculties(res.data.data);
                setFacultyId(res.data.data[0].id);
            }
        }).catch((e) => { console.log(e); });
    }

    useEffect(() => {
        getFaculties();
    }, []);

    useEffect(() => {
        getDepartments();
    }, [filter, toast]);

    const getDepartments = () => {
        AxiosHelper.getDepartments(filter).then((res) => {
            if (res.status == 200) {
                setDepartments(res.data.data);
                setDepartmentsCount(res.data.count);
            }
        }).catch((e) => { console.log(e); });
    }

    const createDepartment = () => {
        if (departmentName == '' || departmentCode == '' || facultyId == '') return;

        const data = { name: departmentName, code: departmentCode, facultyId }
        AxiosHelper.createDepartment(data).then((res) => {
            if (res.status == 200) {
                setModalShow(false);
                setDepartments([...departments, data]);
                setToastMsg({title: Keys.SUCCESS, msg: res.data.msg, variant: 'success'});
                setToast(true);
            }
        }).catch((err) => {
            if (!err?.response) setToastMsg({title: Keys.WARNING, msg: Keys.NO_SERVER_RESPONSE, variant: 'danger'});
            else setToastMsg({title: Keys.WARNING, msg: err.response.data.msg, variant: 'danger'});
            setToast(true);
        });
    }

    const updateDepartment = () => {
        const data = { name: departmentToBeUpdated.name, code: departmentToBeUpdated.code, facultyId }
        AxiosHelper.updateDepartment(departmentToBeUpdated.id, data).then((res) => {
            if (res.status == 200) {
                setModalUpdateShow(false);
                setToastMsg({title: Keys.SUCCESS, msg: res.data.msg, variant: 'success'});
                setToast(true);
            }
        }).catch((err) => {
            if (!err?.response) setToastMsg({title: Keys.WARNING, msg: Keys.NO_SERVER_RESPONSE, variant: 'danger'});
            else setToastMsg({title: Keys.WARNING, msg: err.response.data.msg, variant: 'danger'});
            setToast(true);
        });
    }

    return (
        <Wrapper>
            <div className="d-flex flex-column m-3 p-3 card-16-outline">
                <div className='d-flex align-items-center justify-content-between'>
                    <span className='fs-2 bold mb-3'>{Keys.DEPARTMENTS}</span>
                    <button onClick={() => setModalShow(true)} className='btn card-8 btn-outline-success bg-gradient btn-sm'>
                        <FontAwesomeIcon className='me-2' icon={faPlus}/>{Keys.CREATE_NEW}
                    </button>
                    <ModalU show={modalShow} title={Keys.DEPARTMENT} actionbtnvariant={"primary"} actionbtntext={Keys.SAVE} onSubmit={createDepartment} onHide={() => setModalShow(false)}>
                        <input type="text" name="name" value={departmentName} onChange={(e) => setDepartmentName(e.target.value)} className="form-control mb-2" placeholder={Keys.NAME} required/>
                        <input type="text" name="code" value={departmentCode} onChange={(e) => setDepartmentCode(e.target.value)} className="form-control mb-2" placeholder={Keys.CODE} required/>
                        <Selectable value={facultyId} setValue={setFacultyId} title={Keys.FACULTY} items={faculties}/>
                    </ModalU>
                </div>
                { departmentsCount ?
                    <div className='px-3 pt-3 bg-white rounded-3'>
                        <InputField type={"text"} name={"find_department"} value={findDepartment} onChange={(e) => setDepartments(e.target.value)} hint={Keys.FIND} icon={faSearch} formClass="input-field-container" inputClass="input-field bg-light"/> 
                        <div className="mt-3"></div>
                        <TableStd modelAttrs={modelAttrs} data={departments}/>
                        <div className='col-12 d-flex align-items-center justify-content-between'>
                            <LimitU size={"sm"} filter={filter} setFilter={setFilter}/>
                            <PaginationU filter={filter} setFilter={setFilter} count={departmentsCount} />
                        </div>
                    </div>
                    : <DataNotFound/>
                }
            </div>
            <ToastU show={toast} setShow={setToast} variant={toastMsg.variant} title={toastMsg.title} time={Keys.NOW} content={toastMsg.msg}/>
            <ModalU show={modalUpdateShow} title={Keys.DEPARTMENT} actionbtnvariant={"success"} actionbtntext={Keys.SAVE} onSubmit={updateDepartment} onHide={() => setModalUpdateShow(false)}>
                <input type="text" name="name" value={departmentToBeUpdated.name} onChange={(e) => setDepartmentToBeUpdated({...departmentToBeUpdated, name: e.target.value})} className="form-control mb-2" placeholder={Keys.NAME} required/>
                <input type="text" name="code" value={departmentToBeUpdated.code} onChange={(e) => setDepartmentToBeUpdated({...departmentToBeUpdated, code: e.target.value})} className="form-control mb-2" placeholder={Keys.CODE} required/>
                <Selectable value={facultyId} setValue={setFacultyId} title={Keys.FACULTY} items={faculties}/>
            </ModalU>
        </Wrapper>
    );
}

export default Departments;