import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

const ValidationInstruction = ({...props}) => {
    return (
        <p id={props.id} className={props.attrFocus && props.attr && !props.validAttr ? "instructions" : "offscreen"}>
            <FontAwesomeIcon className="me-2" icon={faInfoCircle} />
            {props.attrInstruction}
        </p>
    );
}

export default ValidationInstruction;