import { faTriangleExclamation, faUserCheck, faUsersViewfinder } from "@fortawesome/free-solid-svg-icons";
import ListGroupU from "../../../components/ui/ListGroupU";
import Keys from "../../../utils/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Admin = () => {
    const listGroup = [
        { name: Keys.USER_ROLES, icon: faUsersViewfinder, link: '/informations/user-roles' },
        { name: Keys.USER_STATUSES, icon: faUserCheck, link: '/informations/user-statuses' }
    ];

    return (
        <div className="card-16-outline bg-danger-accent m-3 p-3">
            <div className="d-flex align-items-center justify-content-between">
                <span className="fs-3 medium mb-2">{Keys.INFORMATIONS}/{Keys.ADMINISTRATOR}</span>
                <span className="badge bg-danger p-2">
                    <FontAwesomeIcon className="me-2" icon={faTriangleExclamation}/>
                    {Keys.SECTION_FOR_ADMIN}
                </span>
            </div>
            <ListGroupU items={listGroup}/>
        </div>
    );
}

export default Admin;