import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-regular-svg-icons";

const ValidationLabel = ({...props}) => {
    return (
        <label htmlFor={props.htmlFor} className="d-flex form-label fg-dark bold">
            {props.label}
            <div className="ms-2">
                <FontAwesomeIcon className={props.validAttr && props.attr2 ? "valid" : "hide"} icon={faCheckCircle}/>
                <FontAwesomeIcon className={props.validAttr || !props.attr ? "hide" : "invalid"} icon={faTimesCircle}/>
            </div>
        </label>
    );
}

export default ValidationLabel;