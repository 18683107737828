import { useEffect } from "react";
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router";
import Header from '../layouts/Header';
import SideBar from "../layouts/SideBar";
import Footer from "../layouts/Footer";

const Wrapper = (props) => {
    const navigate = useNavigate();
    const { auth } = useAuth();
    let loggedIn = true;
    // try { loggedIn = !!auth.token } catch(e) { loggedIn = false };

    useEffect(() => {
        if (!loggedIn) {
            navigate('/auth/login');
        }
    }, [auth]);

    return (
        <div className="d-flex">
            <SideBar/>
            <div className="main">
                <Header/>
                <div className="asidebar">{props.children}</div>
                <Footer/>
            </div>
        </div>
    );
}

export default Wrapper;