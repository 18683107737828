import { faSquareCaretRight } from "@fortawesome/free-regular-svg-icons"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ListGroup } from "react-bootstrap";

const ListGroupU = ({...props}) => {
    return (
        <ListGroup>
            {
                props.items.map((item) => {
                    return(
                        <ListGroup.Item href={item.link} className="d-flex align-items-center justify-content-between" key={item.name} action>
                            <div className="d-flex align-items-center">
                                {item.icon && <FontAwesomeIcon icon={item.icon}/>}
                                <span className="ms-3">{item.name}</span>
                            </div>
                            {item.link && <FontAwesomeIcon className="text-secondary" icon={faChevronRight}/>}
                        </ListGroup.Item>
                    )
                })
            }
        </ListGroup>
    );
}

export default ListGroupU;