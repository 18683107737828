import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CardCountItem = ({...props}) => {

    return (
        <div className="col-md-3">
            <div className="card-8 align-items-center my-2 p-3 bg-white">
                <FontAwesomeIcon className={`rounded-circle ${props.item.bg} p-2 fs-5 text-white`} icon={props.item.icon}/>
                <span className="bold fs-3 my-1">{props.item.count}</span>
                <span className="text-center medium fg-dark">{props.item.name}</span>
            </div>
        </div>
    );
}

export default CardCountItem;