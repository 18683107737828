import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faTrash, faPlus, faSearch, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import InputField from '../../components/form/InputField';
import TableStd from '../../components/ui/TableStd';
import ModalU from '../../components/ui/ModalU';
import DropdownU from '../../components/ui/DropdownU'
import AxiosHelper from '../../api/AxiosHelper';
import Utils from '../../utils';
import Keys from '../../utils/constants';
import Selectable from '../../components/ui/Selectable';
import PaginationU from '../../components/ui/PaginationU';
import LimitU from '../../components/ui/LimitU';
import DataNotFound from '../../components/ui/DataNotFound';

const Main = () => {
    const navigate = useNavigate();
    const [modalStudentDeletionShow, setModalStudentDeletionShow] = useState(false);
    const [studentToBeDeleted, setStudentToBeDeleted] = useState({});
    
    const [students, setStudents] = useState([]);
    const [studentsCount, setStudentsCount] = useState(1);
    const [statuses, setStatuses] = useState([]);
    const [statusId, setStatusId] = useState('');
    const [findStudent, setFindStudent] = useState("");

    const [filter, setFilter] = useState({page:1, limit:10, sort: [['createdAt', 'DESC']]});
    
    useEffect(() => {
        //getStudents();
    },[filter]);

    const tempStudents = [
        {id: '2021102030', fullName: 'Baky Bakyýew', facultyName: 'Bilim', departmentName: 'Matematika', grade: '2', phoneNumber: '62123456', createdAt: '01/09/2021'},
        {id: '2019405060', fullName: 'Aly Weliýew', facultyName: 'Ykdysadyýet', departmentName: 'Hasapçylyk', grade: '4', phoneNumber: '62789756', createdAt: '02/09/2019'},
        {id: '2022708090', fullName: 'Jeren Döwranowa', facultyName: 'Injener', departmentName: 'Gurluşyk Injeneri', grade: '1', phoneNumber: '62646464', createdAt: '01/09/2022'},
        {id: '2021103050', fullName: 'Sähet Hallyýew', facultyName: 'Edebiýat', departmentName: 'Türkmen Dili', grade: '2', phoneNumber: '63415623', createdAt: '01/09/2021'},
        {id: '2020204060', fullName: 'Maýsa Geldiýewa', facultyName: 'Bilim', departmentName: 'Sanly Ulgam', grade: '3', phoneNumber: '64020202', createdAt: '03/09/2020'},
        {id: '2020305070', fullName: 'Sona Çaryýewa', facultyName: 'Mugallymçylyk', departmentName: 'Himiýa', grade: '3', phoneNumber: '63478877', createdAt: '02/09/2020'},
        {id: '2019204060', fullName: 'Kerim Begliýew', facultyName: 'Injener', departmentName: 'Elektrik Injeneri', grade: '4', phoneNumber: '65192837', createdAt: '02/09/2019'},
    ]

    const getStudents = () => {
        AxiosHelper.getStudents(filter).then((res) => {
            if (res.status == 200) {
                setStudents(res.data.data);
                setStudentsCount(res.data.count);
            }
        }).catch((e) => { console.log(e); });
    }

    const deleteStudent = () => {
        AxiosHelper.deleteStudent(studentToBeDeleted.id).then((res) => {
            if (res.status == 200) {
                // todo
            }
        }).catch((e) => { console.log("TEMPORARY NOT ACCESSIBLE REQUEST :|"); });
    }

    const modelAttrs = [ 
        { 
            name: Keys.ID,
            value: (attr) => { return(<Link to={`/students/${attr.id}`} className='no-decor'>{attr.id}</Link>) } 
        },
        {
            name: Keys.FULLNAME,
            value: (attr) => { return(<>{attr.fullName}</>) }
        },
        {
            name: Keys.FACULTY,
            value: (attr) => { return(<>{attr.facultyName}</>) }
        },
        {
            name: Keys.DEPARTMENT,
            value: (attr) => { return(<>{attr.departmentName}</>) }
        },
        {
            name: Keys.GRADE,
            value: (attr) => { return(<>{attr.grade}</>) }
        },
        {
            name: Keys.PHONE_NUMBER,
            value: (attr) => { return(<>{attr.phoneNumber}</>) }
        },
        {
            name: Keys.REGISTRATION_DATE,
            value: (attr) => { return(<>{Utils.getTimestampDate(attr.createdAt)}</>) }
        },
        {
            name: "",
            value: (attr) => {
                const options = [
                    {
                        name: Keys.SHOW,
                        icon: faEye,
                        color: "text-dark",
                        onClick: () => { navigate(`/students/${attr.id}`) },
                    },
                    {
                        name:Keys.DELETE,
                        icon: faTrash,
                        color: "text-danger",
                        onClick: () => { 
                            setModalStudentDeletionShow(true);
                            setStudentToBeDeleted(attr);
                        }
                    }
                ]
                return (
                    <DropdownU className='d-flex align-items-center justify-content-center' toggleClass={"card-8-outline py-2 px-1"} icon={faEllipsisV} items={options}/>
                )
            }
        }
    ];

    return (
        <div className="d-flex flex-column m-3 p-3 card-16-outline">
            <ModalU show={modalStudentDeletionShow} title={Keys.WARNING} actionbtnvariant={"danger"} actionbtntext={Keys.DELETE} onSubmit={deleteStudent} onHide={() => setModalStudentDeletionShow(false)}>
                <div className='d-flex flex-column align-items-center'>
                    <span className='text-dark medium fs-4'>{studentToBeDeleted.fullName}</span>
                    <span className='medium text-danger'>{Keys.DATA_DELETION_CONFIRMATION}</span>
                </div>
            </ModalU>
            <div className='d-flex align-items-center justify-content-between'>
                <span className='fs-2 bold mb-3'>{Keys.STUDENTS}</span>
                <Link to={'/students/create'} className='btn card-8 btn-outline-success bg-gradient btn-sm'>
                    <FontAwesomeIcon className='me-2' icon={faPlus}/>{Keys.CREATE_STUDENT}
                </Link>
            </div>
            { studentsCount ?
                <div className='px-3 pt-3 bg-white rounded-3'>
                    <div className='d-flex align-items-center justify-content-between mb-3'>
                        <div>
                            <InputField type={"text"} name={"find_student"} value={findStudent} onChange={(e) => setFindStudent(e.target.value)} hint={Keys.FIND} icon={faSearch} formClass="input-field-container" inputClass="input-field bg-light"/> 
                        </div>
                        <div className='d-flex align-items-center justify-content-center'>
                            <Selectable value={statusId} setValue={setStatusId} title={Keys.STATUS} items={Keys.ENUM_STATUS} size={"sm"}/>
                        </div>
                    </div>
                    <TableStd modelAttrs={modelAttrs} data={tempStudents}/>
                    <div className='col-12 d-flex align-items-center justify-content-between'>
                        <LimitU size={"sm"} filter={filter} setFilter={setFilter}/>
                        <PaginationU filter={filter} setFilter={setFilter} count={studentsCount} />
                    </div>
                </div>
                : <DataNotFound/>
            }
            
        </div>
    );
}

export default Main;