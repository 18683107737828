import Wrapper from "../../layouts/Wrapper";
import Main from "./Main";

const Users = () => {
    return (
        <Wrapper>
            <Main/>
        </Wrapper>
    );
}

export default Users;