import {Table} from 'react-bootstrap'

const TableStd = ({...props}) => {
    return(
        <Table hover className='small rounded-3 overflow-hidden'>
            <thead className='table-secondary'>
                <tr>
                    {
                        props.modelAttrs.map((attr) => {
                            return (<th scope='col' key={attr.name}>{attr.name}</th>)
                        })
                    }
                </tr>
            </thead>
            <tbody>
                {
                    props.data.map((row) => {
                        return(
                            <tr key={row.id}>
                                {
                                    props.modelAttrs.map((attr) => {
                                        return (<td key={attr.name + row.id}>{ attr.value(row) }</td>)
                                    })
                                }
                            </tr>
                        )
                    })
                }
            </tbody>
        </Table>
    );
}

export default TableStd;