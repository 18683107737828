import { faBuilding } from "@fortawesome/free-regular-svg-icons";
import ListGroupU from "../../../components/ui/ListGroupU";
import Keys from "../../../utils/constants";
import { faUserTie } from "@fortawesome/free-solid-svg-icons";

const Common = () => {
    const listGroup = [
        { name: Keys.FACULTIES, icon: faBuilding, link: '/faculties' },
        { name: Keys.DEPARTMENTS, icon: faBuilding, link: '/departments' },
        { name: Keys.POSITIONS, icon: faUserTie, link: '/positions' },
    ];

    return (
        <div className="card-16-outline m-3 p-3">
            <span className="fs-3 medium mb-2">{Keys.INFORMATIONS}/{Keys.COMMON}</span>
            <ListGroupU items={listGroup}/>
        </div>
    );
}

export default Common;