export default class Keys {
    static USERNAME_REGEX = /^[a-zA-Z][a-zA-Z0-9-_]{5,31}$/;
    static PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%&]).{8,255}$/; // must include at least lowercase, uppercase, numbers, and special chars !@#$%&
    static NAME_REGEX = /^[a-zA-ZäöüňýçşžÄÖÜŇÝÇŞŽ]{2,31}$/; // includes en and tk alphabets
    static PHONE_NUMBER_REGEX = /^[6][1-5][0-9]{6}$/; // starts with 6, second digit in range(1-5), then any number
    static EMAIL_REGEX = /^[a-zA-Z][a-zA-Z0-9!#$%&'*+-/=?^_`{|}~@.]{10,256}$/;
    static DOUBLE_REGEX = /^[1-9][0-9]{0,10}$/;

    static CO_NAME = "Älem Tilsimat"
    static APP_NAME = "Uniwersitet Ulgamy";
    static SIGN_IN = "Giriş";
    static SIGN_UP = "Hasap aç";
    static HAVE_ACCOUNT = "Hasabyňyz barmy?";
    static HAVE_NOT_ACCOUNT = "Hasabyňyz ýokmy?";
    static USERNAME = "Ulanyjy ady";
    static PASSWORD = "Açar sözi";
    static CONFIRM_PASSWORD = "Açar sözi gaýtala";
    static YOUR_USERNAME = "Ulanyjy adyňyz";
    static YOUR_PASSWORD = "Açar sözüňiz";
    static REMAINING_TIME = "Galan wagt:";
    static RESEND_CODE = "Täze kod iber";
    static SUBMIT = "Tassykla";
    static OTP_CODE = "OTP Kod";
    static X_DIGIT_CODE = "6 sanly kod belgisi";
    static ERR_EMPTY_LOGIN_FIELD = "Ulanyjy ady we Açar sözi girizilmedik";
    static ERR_EMPTY_OTP_FIELD = "Kod belgisi girizilmedik";
    static USERNAME_INSTRUCTION = "6-32 aralygynda simwol. Harp bilen başlamaly. Harplar, sanlar, aşagy çyzgy, we kese çyzgy rugsat berilen.";
    static PASSWORD_INSTRUCTION = "8-256 aralygynda simwol. Içinde Baş we setir harplar, san we ýörite simwollar bolmaly. Rugsat berilen ýörite simwollar: !@#$%&";
    static MATCH_PASSWORD_INSTRUCTION = "Açar sözi - tassyklanan Açar sözi bilen gabat gelmeli."
    static NAME_INSTRUCTION = "2-32 aralygynda harp. Harplar rugsat berilen"
    static PHONE_NUMBER_INSTRUCTION = "8 sandan ybarat bolup, 6-lyk sany bilen başlamaly";
    static EMAIL_INSTRUCTION = "10-256 aralygynda simwol";
    static INVALID_ENTRY = "Ýalňyş girizilen maglumatlar";
    static NOW = "Şu wagt";
    static ERROR = "Ýalňyşlyk ýüze çykdy";
    static SUCCESS = "Üstünlikli";
    static NO_SERVER_RESPONSE = "Serwerden jogap gelmedi";
    static USERNAME_TAKEN = "Girizen ulanyjy adyňyz öňden alynan";
    static REGISTRATION_FAILED = "Hasap açmakda şowsyz";

    static MAIN = "Esasy";
    static MAIN_PAGE = "Baş sahypa";
    static PROFILE = "Profil";
    static SETTINGS = "Sazlamalar";
    static USERS = "Ulanyjylar";
    static INFORMATIONS = "Maglumatlar";

    static COMMON = "Umumy";
    static ADMIN = "Admin";
    static USER = "Ulanyjy";
    static ADMINISTRATOR = "Administrator";
    static NOTIFICATIONS = "Bildirişler";
    static SERVER = "Serwer";
    static TURN_OFF_SERVER = "Serweri öçür";
    static TECHNICAL_DIFFICULT = "Tehniki näsazlyk";
    static APP_VERSION = "Programmanyň wersiýasy";

    static CATEGORIES = "Kategoriýalar";
    static EVENTS = "Çäreler";
    static USER_ROLES = "Ulanyjy rollary";
    static USER_STATUSES = "Ulanyjy statuslary";
    static SERVER_SETTINGS = "Serwer sazlamalary";

    static OK = "Ok";
    static NEXT = "Dowam";
    static BACK = "Yza";
    static DONE = "Tamamlandy";
    static OK = "Bolýar";
    static ADD = "Goş";
    static HELP = "Kömek";
    static FIND = "Gözle";
    static EXIT = "Çykyş";
    static DETAIL = "Giňişleýin"
    static SHOW = "Görkez";
    static EDIT = "Düzelt";
    static DELETE = "Poz";
    static COMPLETE = "Tamamla";
    static SAVE = "Ýatda sakla";
    static CANCEL = "Reddet";
    static UPDATE = "Täzele";
    static NAME = "Ady";
    static DATA_NOT_FOUND = "Maglumat tapylmady";
    static WARNING = "Duýduryş";
    static DANGER = "Howply";
    static CONFIRM = "Tassykla";
    static CONFIRMATION = "Tassyklamak";
    static DEFINITION = "Beýany";
    static ACCOUNT = "Hasap";
    static PROCESSES = "Hereketler";
    static CREATED_DATE = "Döredilen senesi";
    static LAST_UPDATE = "Soňky düzeldiş";
    static TOTAL = "Jemi";
    static UPLOAD = "Ýükle";
    static UPLOAD_PHOTO = "Surat ýükle";

    static CREATE_USER = "Ulanyjy döret";
    static CREATE_STUDENT = "Talyp döret";
    static ID = "ID";
    static GRADE = "Kurs";
    static TEACHERS = "Mugallymlar";
    static FIRSTNAME = "Ady";
    static LASTNAME = "Familiýasy";
    static MIDDLENAME = "Atasynyň ady";
    static FULLNAME = "Ady Familiýasy";
    static PHONE_NUMBER = "Telefon belgisi";
    static EMAIL = "E-poçta"
    static YOUR_FIRSTNAME = "Adyňyz";
    static YOUR_LASTNAME = "Familiýaňyz";
    static YOUR_MIDDLENAME = "Ataňyzyň ady";
    static YOUR_PHONE_NUMBER = "Telefon belgiňiz";
    static REGISTRATION_DATE = "Registrasiýa senesi";
    static STATUS = "Status";
    static ACTIVE = "Işjeň";
    static INACTIVE = "Işjeň däl";
    static CHOOSE = "Saýla";
    static ALL = "Ählisi";
    static ROLE = "Roly";
    static GENDER = "Jynsy";
    static CONTACT = "Kontakt";
    static ADDRESS = "Salgysy";
    static PROVINCE = "Welaýat";
    static DISTRICT = "Şäher/Etrap";
    static CODE = "KODY";

    static DATE = "Senesi";
    static CREATION_DATE = "Döredilen senesi";
    static DESCRIPTION = "Mazmuny";
    static CATEGORY = "Kategoriýasy";
    static FACULTY = "Fakultet";
    static DEPARTMENT = "Kafedra";
    static DOUBLE_INSTRUCTION = "Diňe pozitiw sanlar";
    static FACULTIES = "Fakultetler";
    static DEPARTMENTS = "Kafedralar";
    static STUDENT = "Talyp";
    static STUDENTS = "Talyplar";
    static POSITION = "Wezipe";
    static POSITIONS = "Wezipeler";
    static STATISTICS = "Statistikalar";
    static DEPARTMENT_COUNT = "Kafedra sany";
    static CREATE_NEW = "Täze döret";

    static TODAYS_STA = "Şu günki Statistika";

    static PUBLISHED = "Ýaýynlandy";
    static ISSUE_DATE = "Başlangyç senesi";
    static DUE_DATE = "Soň senesi";
    static READ_MORE = "Doly oka";
    static ABOUT = "Hakynda";
    static POWERED_BY = "Powered by Älem Tilsimat";
    static DETAILED_INFO = "Giňişleýin maglumat üçin"
    static TERMS_OF_CONDITIONS = "Şertler";
    static PRIVACY_POLICY = "Gizlinlik we Syýasat";
    static INSTRUCTION_FOR_USING_SOFTWARE = "Programmany ulanmak boýunça görkezmeler";
    static SHOW_ALL = "Ählisini görkez";

    static HELP_DESCRIPTION = "Ulgama girmek üçin Size Administrator tarapyndan berilen \"Ulanyjy adyňyzy\" we \"Açar sözüňizi\" girizmegiňiz gerekli.\nEger-de size hasap açylmadyk bolsa, Administratoryňyza ýüz tutmagyňyzy haýyşt edýäris.";
    static SUPPORT = "Goldaw";
    static TECH_SUPPORT = "Admin panel hakynda Tehniki goldaw almak";
    static SECTION_FOR_ADMIN = "Administrator ygtyýarlygy bolan ulanyjylar üçin niýetlenen Dolandyryş bölümi!";
    static DATA_DELETION_CONFIRMATION = "Siz bu maglumaty pozmagy tassyklaýarsyňyzmy?";
    static STUDENT_DELETION_CONFIRMATION = "Siz bu talyby pozmagy tassyklaýarsyňyzmy?";
    static USER_DELETION_CONFIRMATION = "Siz bu ulanyjyny pozmagy tassyklaýarsyňyzmy?";

    static ENUM_STATUS = [{id: '1', code: "active", name: "Işjeň"}, {id: '2', code: "inactive", name: "Işjeň däl"}];
    static ENUM_GENDER = [{id: '1', code: 'M', name: 'Erkek'}, {id: '2', code: 'F', name: 'Aýal'}];

    static STATUSES = new Map([
        ['1', "Işjeň"], ['2', 'Çykdaýjy']
    ]);

    static MONTHS = new Map([
        ['01', 'Ýanwar'], ['02', 'Fewral'], ['03', 'Mart'], ['04', 'Aprel'], ['05', 'Maý'], ['06', 'Iýun'],
        ['07', 'Iýul'], ['08', 'Awgust'], ['09', 'Sentýabr'], ['10', 'Oktýabr'], ['11', 'Noýabr'], ['12', 'Dekabr'], 
    ]);

    static COPYRIGHT = "Copyright © 2023 Uniwersitet Ulgamy"
}