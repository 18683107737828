import Wrapper from "../../layouts/Wrapper";
import TabBar from "../../components/ui/TabBar";
import Keys from "../../utils/constants";
import Notifications from './Tabs/Notifications'
import Server from './Tabs/Server'

const Information = () => {
    const tabs = [
        { eventKey: 'notifications', title: Keys.NOTIFICATIONS, content: <Notifications/> },
        { eventKey: 'server', title: Keys.SERVER, content: <Server/> }
    ]

    return (
        <Wrapper>
            <TabBar tabs={tabs} className={"m-3"}/>
        </Wrapper>
    );
}

export default Information;