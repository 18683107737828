import { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";

const TabBar = ({...props}) => {
    const [key, setKey] = useState(props.tabs[0].eventKey);

    return (
        <Tabs className={props.className} activeKey={key} onSelect={(k) => { setKey(k); }}>
            {
                props.tabs.map((tab) => {
                    return (
                        <Tab eventKey={tab.eventKey} title={tab.title} key={tab.eventKey}>
                            { tab.content }
                        </Tab>
                    )
                })
            }
        </Tabs>
    );
}

export default TabBar;