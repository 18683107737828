import Wrapper from "../../layouts/Wrapper";
import Main from "./Main";

const User = () => {
    return (
        <Wrapper>
            <Main/>
        </Wrapper>
    );
}

export default User;