import { useEffect, useState } from 'react';
import useAuth from '../../hooks/useAuth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faSquareCaretLeft } from '@fortawesome/free-solid-svg-icons';
import AxiosHelper from '../../api/AxiosHelper';
import Keys from '../../utils/constants';
import Info from '../User/info';
import Update from '../User//update';

const Main = () => {
    const { auth } = useAuth();
    const [profile, setProfile] = useState({});

    const [toggleBtn, setToggleBtn] = useState({class: 'btn-outline-success', text: Keys.EDIT, icon: faPenToSquare});
    const [toggleUserInfoUpdate, setToggleUserInfoUpdate] = useState(true);

    const toggleUserUi = () => {
        setToggleUserInfoUpdate(!toggleUserInfoUpdate);
        !toggleUserInfoUpdate ?
        setToggleBtn({class: 'btn-outline-success', text: Keys.EDIT, icon: faPenToSquare})
        : setToggleBtn({class: 'btn-outline-warning', text: Keys.BACK, icon: faSquareCaretLeft});
    }

    useEffect(() => {
        getUser();
    }, [auth]);

    const getUser = () => {
        AxiosHelper.getUser(auth.user.id).then((res) => {
            if (res.status == 200) {
                setProfile(res.data.data);
            }
        }).catch((e) => { console.log(e); });
    }

    return (
        <div className="d-flex flex-column m-3 p-3 card-16-outline">
            <div className='d-flex align-items-center justify-content-between'>
                <span className='fs-3 bold mb-3'>{Keys.PROFILE}</span>
                <span className='fs-3 bold mb-3'>@{auth.user.username}</span>
                <button className={`btn card-8 bg-gradient btn-sm ${toggleBtn.class}`} onClick={toggleUserUi}>
                    <FontAwesomeIcon className='me-2' icon={toggleBtn.icon}/>{toggleBtn.text}
                </button>
            </div>
            {toggleUserInfoUpdate ? <Info user={profile}/> : <Update user={profile}/> }
        </div>
    );
}

export default Main;