import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUniregistry } from '@fortawesome/free-brands-svg-icons';
import Keys from "../utils/constants";
import qrAt from '../assets/qr_at.png';

const Footer = () => {
    return (
        <div className="footer flex-column mt-5 px-3 pt-3">
            <div className="d-flex w-100 justify-content-between">
                <div className="d-flex flex-column">
                    <span>{Keys.DETAILED_INFO}</span>
                    <img className="qr-code mt-2" src={qrAt} alt="" />
                </div>
                <div className="d-flex flex-column">
                    <Link className="no-decor fg-dark">{Keys.INSTRUCTION_FOR_USING_SOFTWARE}</Link>
                    <Link className="no-decor fg-dark">{Keys.PRIVACY_POLICY}</Link>
                    <Link className="no-decor fg-dark">{Keys.TERMS_OF_CONDITIONS}</Link>
                </div>
                <div>
                    <Link to={'https://alemtilsimat.com/'} className="no-decor">{Keys.POWERED_BY}</Link>
                    <Link to={'/'} className="d-flex align-items-center no-decor mx-2 mt-2 mb-3">
                        <FontAwesomeIcon className='bg-dark text-white logo-sm me-2' icon={faUniregistry}/>
                        <span className="fs-4 text-dark bold">{Keys.APP_NAME}</span>
                    </Link>
                </div>
            </div>
            <span className="small mt-3 bg-light text-center rounded-2 w-100">{Keys.COPYRIGHT}</span>
        </div>
    );
}

export default Footer;