import Keys from "../../utils/constants";

const Main = () => {
    return (
        <div className="d-flex flex-column m-3 p-3 card-16-outline">
            <span className='fs-2 bold mb-3'>{Keys.STATISTICS}</span>
        </div>
    );
}

export default Main;