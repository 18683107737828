import { useState, useEffect } from 'react';
import Keys from '../../utils/constants';
import Status from '../../components/ui/Status';
import Selectable from '../../components/ui/Selectable';
import Utils from '../../utils';
import AxiosHelper from '../../api/AxiosHelper';
import ToastU from '../../components/ui/ToastU';

const Update = ({...props}) => {
    const user = props.user;

    const [faculties, setFaculties] = useState([]);
    const [departments, setDepartments] = useState([]);

    const [userToBeUpdated, setUserToBeUpdated] = useState(user);
    const [facultyId, setFacultyId] = useState('');
    const [departmentId, setDepartmentId] = useState('');

    const [toast, setToast] = useState(false);
    const [toastMsg, setToastMsg] = useState({});

    const getFaculties = () => {
        AxiosHelper.getFaculties({}).then((res) => {
            if (res.status == 200) {
                setFaculties(res.data.data);
            }
        }).catch((e) => { console.log(e); });
    }

    const getDepartments = () => {
        if (!facultyId) return;
        const filter = {facultyId: facultyId};

        AxiosHelper.getDepartments(filter).then((res) => {
            if (res.status == 200) {
                setDepartments(res.data.data);
            }
        }).catch((e) => { console.log(e); });
    }

    useEffect(() => {
        getFaculties();
    }, []);

    useEffect(() => {
        getDepartments();
    }, [facultyId]);

    const handleSubmit = (event) => {
        event.preventDefault();

        const data = {
            phoneNumber: userToBeUpdated.phoneNumber, facultyId, departmentId
        };

        AxiosHelper.updateUser(user.id, data).then((res) => {
            if (res.status == 200) {
                setToastMsg({title: Keys.SUCCESS, msg: res.data.msg, variant: 'success'});
                setToast(true);
            }
        }).catch((err) => {
            if (!err?.response) setToastMsg({title: Keys.WARNING, msg: Keys.NO_SERVER_RESPONSE, variant: 'danger'});
            else setToastMsg({title: Keys.WARNING, msg: err.response.data.msg, variant: 'danger'});
            setToast(true);
        });
    }

    return (
        <div>
            <form onSubmit={handleSubmit} noValidate>
                <div className='row'>
                    <div className="col-md-4">
                        <label className="form-label text-dark bold">{Keys.FIRSTNAME}</label>
                        <input type="text" name="firstName" value={userToBeUpdated.firstName} onChange={(e) => setUserToBeUpdated({...userToBeUpdated, firstName: e.target.value})} className="form-control" placeholder={Keys.FIRSTNAME} required disabled/>
                    </div>
                    <div className="col-md-4">
                        <label className="form-label text-dark bold">{Keys.LASTNAME}</label>
                        <input type="text" name="lastName" value={userToBeUpdated.lastName} onChange={(e) => setUserToBeUpdated({...userToBeUpdated, lastName: e.target.value})} className="form-control" placeholder={Keys.LASTNAME} required disabled/>
                    </div>
                    <div className="col-md-4">
                        <label className="form-label text-dark bold">{Keys.MIDDLENAME}</label>
                        <input type="text" name="middleName" value={userToBeUpdated.middleName} onChange={(e) => setUserToBeUpdated({...userToBeUpdated, middleName: e.target.value})} className="form-control" placeholder={Keys.MIDDLENAME} required disabled/>
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className="col-md-4">
                        <label className="form-label text-dark bold">{Keys.PHONE_NUMBER}</label>
                        <input type="number" name="phoneNumber" value={userToBeUpdated.phoneNumber} onChange={(e) => setUserToBeUpdated({...userToBeUpdated, phoneNumber: e.target.value})} className="form-control" placeholder={Keys.PHONE_NUMBER} required/>
                    </div>
                    <Selectable className={"col-md-4 mt-2"} value={facultyId} setValue={setFacultyId} title={Keys.FACULTY} items={faculties}/>
                    <Selectable className={"col-md-4 mt-2"} value={departmentId} setValue={setDepartmentId} title={Keys.DEPARTMENT} items={departments}/>
                </div>
                
                <button type="submit" className="btn btn-success bg-gradient mt-4">{Keys.SAVE}</button>
            </form>
            <ToastU show={toast} setShow={setToast} variant={toastMsg.variant} title={toastMsg.title} time={Keys.NOW} content={toastMsg.msg}/>
        </div>
    );
}

export default Update;