import { FormSelect } from "react-bootstrap";
import Keys from "../../utils/constants";

const Selectable = ({...props}) => {
    return (
        <div className={props.className}>
            <span className="medium fg-dark mb-2">{props.title}</span>
            <FormSelect className="form-select form-select-sm" size={props.size} value={props.value} onChange={(e) => {props.setValue(e.target.value)}}>
                <option value={""} selected disabled hidden>{Keys.CHOOSE}</option>
                {
                    props.items.map((item) => {
                        return (<option value={item.id} key={item.id}>{item.name}</option>)
                    })
                }
            </FormSelect>
        </div>
    );
}

export default Selectable;