import React, { useEffect } from "react";
import useAuth from "../hooks/useAuth";
import AxiosHelper from "../api/AxiosHelper";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { Dropdown } from "react-bootstrap";
import Utils from "../utils/index";
import Keys from "../utils/constants";

const DropdownProfile = () => {
  const { auth, setAuth } = useAuth();
  const logout = () => {
    AxiosHelper.logout().then((result) => {
        if (result.status == 200) {
          setAuth(null);
        }
    });
  }

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span
      className="avatar bg-blue-400 bold ms-2"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      >
      {Utils.getAvatar(auth.user)}
    </span>
  ));

  return (
    !(auth == null || auth.user == null) ?
    <Dropdown className="large">
      <Dropdown.Toggle as={CustomToggle}>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.ItemText className="text-center px-5">{auth.user.firstName} {auth.user.lastName}</Dropdown.ItemText>
        <Dropdown.Item href="/profile">Profil</Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={logout}>
          <div className="d-flex align-items-center justify-content-between bg-danger bg-gradient rounded-2 py-2 px-3">
            <span className="text-white">{Keys.EXIT}</span>
            <FontAwesomeIcon className='text-white fs-6' icon={faArrowRightFromBracket}/>
          </div>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
    : null
  );
}

export default DropdownProfile;